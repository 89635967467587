import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import Input from 'src/components/Input';
import RegisterBirthdayStep from 'src/components/LoginModal/Steps/Register/BirthdayStep';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import { emailVerificationAtom, isRemoveOldDeviceUserDetailsAtom, phoneNumberLoginEmailAtom, loginAfterMatchingEmailAtom, pushPhoneNumberLoginHistoryAtom } from 'src/stores/phoneNumberLogin/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { emailReg } from 'src/utils/common';
import Template from './Template';
const EmailRow = styled.div`
  margin-top: 32px;
  width: 100%;
`;
const formId = 'email-address-check-step-form';

/** 이미 가입된 계정이 있으나 디바이스 변경으로 이메일 주소 확인이 필요한 경우 */
const EmailAddressMatchStep: FC = () => {
  const {
    t
  } = useTranslation();
  const device = useCssMediaDevice();
  const pushEvent = useEvent();
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPENED__EMAIL_INFO
    });
  }, [pushEvent]);
  const loginAfterMatchingEmail = useSetAtom(loginAfterMatchingEmailAtom);
  const [email, setEmail] = useState('');
  const userEmail = useAtomValue(emailVerificationAtom);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [phoneNumberLoginEmail, setPhoneNumberLoginEmail] = useAtom(phoneNumberLoginEmailAtom);
  const handleSubmit = async () => {
    setIsLoadingLogin(true);
    setPhoneNumberLoginEmail({
      email,
      errorMessage: ''
    });
    await loginAfterMatchingEmail();
    setIsLoadingLogin(false);
  };
  const handleInput = (text: string) => {
    setEmail(text);
    if (phoneNumberLoginEmail.errorMessage) {
      setPhoneNumberLoginEmail({
        ...phoneNumberLoginEmail,
        errorMessage: ''
      });
    }
  };
  const canVerifyEmail = emailReg(email) && !phoneNumberLoginEmail.errorMessage;
  const setRemoveOldDeviceUserDetails = useSetAtom(isRemoveOldDeviceUserDetailsAtom);
  const pushHistory = useSetAtom(pushPhoneNumberLoginHistoryAtom);

  // 기존 PhoneNumber 계정 삭제하고, 동일 PhoneNumber로 새 계정 생성
  const createNewAccount = () => {
    setRemoveOldDeviceUserDetails(true);
    setPhoneNumberLoginEmail({
      ...phoneNumberLoginEmail,
      errorMessage: ''
    });
    pushHistory(<RegisterBirthdayStep />);
  };
  return <>
      <Template eventId='LOGIN__EMAIL__ADDRESS__CHECK' title={t('SIGNUP_PHONE_EMAIL_TITLE')} description={t('signup_phone_verified_desc', {
      email: userEmail.email
    })} mainButtonProps={{
      form: formId,
      type: 'submit',
      loading: isLoadingLogin,
      disabled: !canVerifyEmail,
      text: t('SIGNUP__NEXT')
    }} subButtonProps={{
      onClick: createNewAccount,
      text: t('signup_phone_btn_reg'),
      color: 'secondary'
    }} data-sentry-element="Template" data-sentry-source-file="EmailAddressMatchStep.tsx">
        <EmailRow data-sentry-element="EmailRow" data-sentry-source-file="EmailAddressMatchStep.tsx">
          <form id={formId} onSubmit={e => {
          e.preventDefault();
          if (canVerifyEmail) {
            handleSubmit();
          }
        }}>
            <Input type='email' value={email} onChangeText={handleInput} placeholder={t('SIGNUP_PHONE_EMAIL_PLACEHOLDER')} errorMessage={phoneNumberLoginEmail.errorMessage} autoFocus={device !== 'mobile'} data-sentry-element="Input" data-sentry-source-file="EmailAddressMatchStep.tsx" />
          </form>
        </EmailRow>
      </Template>
    </>;
};
export default EmailAddressMatchStep;