import { ReactElement } from 'react';
import * as Sentry from '@sentry/nextjs';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';
import SmsVerificationVerifyCodeStep from 'src/components/LoginModal/Steps/SmsVerification/VerifyCodeStep';
import { loginWithPhoneNumberAtom, showInduceRegisterModalAtom } from 'src/stores/auth/atoms';
import { featureSettingParamsFromOptionalConsentsAtom, LoginModalType, loginModalTypeAtom, pushSocialRegisterHistoryAtom } from 'src/stores/register/atoms';
import { pushSocialLoginHistoryAtom } from 'src/stores/socialLogin/atom';
import { ErrorAlertMessage, ErrorResponse } from 'src/utils/api';
import getDeviceId from 'src/utils/device/id';
import { isAxiosError } from 'src/utils/error';
import { matchEmailAPI } from './apis';
import { DeviceTransferMatchEmailErrorCode, deviceTransferMatchEmailErrorCodeTranslationMap, VerifyAccountResponse, VerifyPreparation } from './types';
export const phoneNumberLoginHistoryAtom = atomWithReset<JSX.Element[]>([]);
export const pushPhoneNumberLoginHistoryAtom = atom(null, (get, set, nextStep: ReactElement) => {
  set(phoneNumberLoginHistoryAtom, history => [...history, nextStep]);
});
export const popPhoneNumberLoginHistoryAtop = atom(null, (get, set) => {
  set(phoneNumberLoginHistoryAtom, history => history.slice(0, -1));
});
export const errorMessageAtom = atom<undefined | string>('');
const initSmsPhoneNumberValue = {
  phone: '',
  verifyPreparation: {
    countryName: undefined,
    countryPrefixNumber: undefined
  },
  errorMessage: ''
};
export const smsPhoneNumberAtom = atomWithReset<{
  phone: string;
  verifyPreparation: VerifyPreparation;
  errorMessage?: string;
}>(initSmsPhoneNumberValue);
const initSmsVerificationValue = {
  verifyCode: '',
  formattedPhoneNumber: '',
  errorMessage: ''
};
export const smsVerificationAtom = atomWithReset<{
  verifyCode: string;
  formattedPhoneNumber: string;
  errorMessage?: string;
  isError?: boolean;
}>(initSmsVerificationValue);
export const nextSmsVerificationStepAtom = atom(null, (get, set) => {
  const loginModalType = get(loginModalTypeAtom);
  switch (loginModalType) {
    case LoginModalType.SOCIAL_REGISTER:
      set(pushSocialRegisterHistoryAtom, <SmsVerificationVerifyCodeStep />);
      break;
    case LoginModalType.SOCIAL_LOGIN:
      {
        set(pushSocialLoginHistoryAtom, <SmsVerificationVerifyCodeStep />);
        break;
      }
    case LoginModalType.PHONE_NUMBER_LOGIN:
      {
        set(pushPhoneNumberLoginHistoryAtom, <SmsVerificationVerifyCodeStep />);
        break;
      }
  }
});
export const smsVerificationResponseAtom = atom<VerifyAccountResponse | null>(null);
const initEmailVerificationValue = {
  email: ''
};
export const emailVerificationAtom = atomWithReset(initEmailVerificationValue);
const initPhoneNumberLoginEmailValue = {
  email: '',
  errorMessage: ''
};
export const phoneNumberLoginEmailAtom = atom(initPhoneNumberLoginEmailValue);
const resetLoginAtom = atom(null, (_get, set) => {
  set(smsPhoneNumberAtom, initSmsPhoneNumberValue);
  set(smsVerificationAtom, initSmsVerificationValue);
  set(phoneNumberLoginEmailAtom, initPhoneNumberLoginEmailValue);
  set(phoneNumberLoginHistoryAtom, RESET);
  set(emailVerificationAtom, RESET);
});
export const isRemoveOldDeviceUserDetailsAtom = atom(false);
export const verifyErrorMessages: {
  [key: string]: string;
} = {
  VERIFICATION_FAILED: 'SIGNUP_PHONE_VERIFICATION_FAIL'
};
export const loginAfterMatchingEmailAtom = atom(null, async (get, set) => {
  const smsVerificationResponse = get(smsVerificationResponseAtom);
  if (!smsVerificationResponse) {
    Sentry.captureMessage('loginAfterMatchingEmailAtom called without smsVerificationResponse');
    return;
  }
  const {
    phoneId,
    phoneToken
  } = smsVerificationResponse;
  try {
    await matchEmailAPI({
      email: get(phoneNumberLoginEmailAtom).email,
      phoneId,
      phoneToken,
      deviceId: getDeviceId()
    });
    await set(loginWithPhoneNumberAtom);
    set(featureSettingParamsFromOptionalConsentsAtom, RESET);
  } catch (error) {
    if (!isAxiosError<ErrorResponse<ErrorAlertMessage>>(error)) {
      Sentry.captureException(error);
      return;
    }
    const {
      response
    } = error;
    set(phoneNumberLoginEmailAtom, {
      ...get(phoneNumberLoginEmailAtom),
      errorMessage: deviceTransferMatchEmailErrorCodeTranslationMap[response?.data.error.code as DeviceTransferMatchEmailErrorCode] || deviceTransferMatchEmailErrorCodeTranslationMap.VERIFICATION_FAILED
    });
  }
});
export const loginModalHeaderAtom = atom<{
  component?: ReactElement;
  onBack?: () => void;
} | void>(undefined);
export const exitLoginModalAtom = atom(null, (get, set) => {
  set(loginModalTypeAtom, LoginModalType.LOGIN_ENTRY);
  set(resetLoginAtom);

  // 가입 단계에서 이탈 시 2초 후 Bounce Rate 모달 노출
  set(showInduceRegisterModalAtom);
});