import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import { useTurnstile } from 'react-turnstile';
import Button from 'src/components/Button';
import { ErrorMessage } from 'src/components/Input';
import { VERIFICATION_CODE_RESEND_BLOCK_TIMEOUT, VERIFICATION_CODE_RESEND_BLOCK_TIMEOUT_WITH_BUFFER } from 'src/components/LoginModal/constants';
import { LoginModalHeader, Wrap } from 'src/components/LoginModal/styles';
import { ModalFooter, ModalTitle } from 'src/components/ModalLayout';
import TurnstileWidget from 'src/components/Turnstile';
import VerifyCodeInput from 'src/components/VerifyCode';
import useEvent from 'src/hooks/useEvent';
import useSendSmsVerificationCode from 'src/hooks/useSendSmsVerificationCode';
import useTimer from 'src/hooks/useTimer';
import useVerifyCode from 'src/hooks/useVerifyCode';
import { turnstileWidgetSiteKeyAtom } from 'src/stores/auth/atoms';
import { smsPhoneNumberAtom, smsVerificationAtom } from 'src/stores/phoneNumberLogin/atoms';
import { VERIFY_CODE_LENGTH } from 'src/stores/phoneNumberLogin/types';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import getDeviceInfo from 'src/utils/device/info';
const DescWrap = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-bottom: 32px;
`;
const Desc = styled.p`
  font-size: 16px;
  margin-bottom: 4px;
  color: ${({
  theme
}) => theme.color.gray700__dkGray700};
`;
const ResendRow = styled.div`
  display: flex;
  align-items: flex-start;
`;
const ResendText = styled.button`
  background-color: transparent;
  text-decoration: underline;
  color: ${({
  theme
}) => theme.color.gray700__dkGray700};
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  padding: 0;
`;
const WarningText = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 14px;
  word-break: keep-all;

  color: ${({
  theme
}) => theme.color.gray500__dkGray400};
  ${({
  theme
}) => theme.screenSize.mobile} {
    margin-bottom: 8px;
  }
`;
const InfoText = styled(WarningText)`
  font-size: 14px;
`;
const TimerText = styled(WarningText)`
  font-size: 14px;
  color: ${({
  theme
}) => theme.color.green500};
`;
const SmsVerificationVerifyCodeStep: FC = () => {
  const {
    t
  } = useTranslation();
  const pushEvent = useEvent();
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPENED__PHONE_VERIFICATION
    });
  }, [pushEvent]);
  const smsPhoneNumber = useAtomValue(smsPhoneNumberAtom);
  const [code, setCode] = useState('');
  const {
    time,
    start,
    changeTimer
  } = useTimer({
    endTime: VERIFICATION_CODE_RESEND_BLOCK_TIMEOUT,
    increment: false
  });
  useEffect(() => {
    start();
  }, [start]);
  const [smsVerification, setSmsVerification] = useAtom(smsVerificationAtom);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    isMobile
  } = getDeviceInfo();
  const {
    isLoading: isVerifyingCode,
    handleVerifyCode
  } = useVerifyCode();
  const errorMessage = smsPhoneNumber.errorMessage || smsVerification.errorMessage;
  const handleVerify = (verifyCode: string) => {
    if (isMobile) {
      inputRef.current?.blur();
    }
    setSmsVerification({
      ...smsVerification,
      verifyCode
    });
    handleVerifyCode(verifyCode);
  };
  const isVerifyCodeFilledButInvalid = () => !!(code.length >= VERIFY_CODE_LENGTH && smsVerification.errorMessage);
  const handleChangeVerifyCode = (verifyCode: string) => {
    const numReg = /^$|^[0-9]+$/;
    if (!numReg.test(verifyCode)) return;

    // 실패된 인증코드 상태에서 추가 입력시 초기화
    if (isVerifyCodeFilledButInvalid()) {
      verifyCode = verifyCode.slice(VERIFY_CODE_LENGTH);
    }
    if (verifyCode.length > VERIFY_CODE_LENGTH) return;
    setCode(verifyCode);
    if (smsVerification.errorMessage) {
      setSmsVerification({
        ...smsVerification,
        errorMessage: ''
      });
    }
    if (verifyCode.length === VERIFY_CODE_LENGTH) {
      handleVerify(verifyCode);
    }
  };
  const handleFocusVerifyCodeInput = () => {
    if (isVerifyCodeFilledButInvalid()) {
      setCode('');
    }
  };
  const {
    send: sendSmsVerificationCode,
    isLoading: isSendingSmsVerificationCode
  } = useSendSmsVerificationCode();
  const handleResend = useCallback(async () => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SIGN_UP_CLICK_RESEND
    });
    await sendSmsVerificationCode();
    changeTimer(VERIFICATION_CODE_RESEND_BLOCK_TIMEOUT_WITH_BUFFER);
  }, [changeTimer, pushEvent, sendSmsVerificationCode]);
  const TimerMessage = useCallback(() => {
    // 인증번호 발송 관련 에러 발생했을 때에는 타이머 미노출
    if (smsPhoneNumber.errorMessage) {
      return null;
    }
    if (Number(time.second) === 0) {
      return <ResendRow>
          <ResendText onClick={handleResend}>{t('SIGNUP_PHONE_CODE_RETRY')}</ResendText>
        </ResendRow>;
    }
    if (isSendingSmsVerificationCode) {
      return <InfoText> {t('SIGNUP_PHONE_CODE_REQUEST')}</InfoText>;
    }
    if (Number(time.second) > VERIFICATION_CODE_RESEND_BLOCK_TIMEOUT) {
      return <InfoText> {t('SIGNUP_PHONE_CODE_RESENT')}</InfoText>;
    }
    return <TimerText>
        {t('device_transfer__resend_code_delay', {
        time: `${time.minute}:${time.second}`
      })}
      </TimerText>;
  }, [handleResend, isSendingSmsVerificationCode, smsPhoneNumber.errorMessage, t, time.minute, time.second]);
  const turnstileWidgetSiteKey = useAtomValue(turnstileWidgetSiteKeyAtom);
  const InfoMessage = useCallback(() => {
    if (errorMessage) {
      return <ErrorMessage message={errorMessage} />;
    }
    if (Number(time.second) === 0) {
      return <WarningText>{t('SIGNUP_PHONE_NUMBER_SPAM')}</WarningText>;
    }
    return null;
  }, [errorMessage, t, time.second]);
  const turnstile = useTurnstile();
  return <>
      <Wrap data-sentry-element="Wrap" data-sentry-source-file="VerifyCodeStep.tsx">
        <LoginModalHeader data-testid='LoginModal-SmsVerification-VerifyCodeStep' data-sentry-element="LoginModalHeader" data-sentry-source-file="VerifyCodeStep.tsx">
          <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="VerifyCodeStep.tsx">{t('SIGNUP_PHONE_VERIFICATION_TITLE')}</ModalTitle>
        </LoginModalHeader>
        <DescWrap data-sentry-element="DescWrap" data-sentry-source-file="VerifyCodeStep.tsx">
          <Desc data-sentry-element="Desc" data-sentry-source-file="VerifyCodeStep.tsx">
            +{smsPhoneNumber.verifyPreparation.countryPrefixNumber} {smsPhoneNumber.phone}
          </Desc>
          <Desc data-sentry-element="Desc" data-sentry-source-file="VerifyCodeStep.tsx">{t('SIGNUP_PHONE_VERIFY_TITLE')}</Desc>
        </DescWrap>
        {turnstileWidgetSiteKey ? <TurnstileWidget callback={async () => {
        await sendSmsVerificationCode();
        turnstile.reset();
      }} /> : <>
            <VerifyCodeInput isError={!!smsVerification.errorMessage} code={code} onChange={handleChangeVerifyCode} onFocus={handleFocusVerifyCodeInput} numOfArray={VERIFY_CODE_LENGTH} inputRef={inputRef} isEditable={!isVerifyingCode} />
            <InfoMessage />
            <TimerMessage />
          </>}
      </Wrap>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="VerifyCodeStep.tsx">
        <Button id='SIGNUP__NEXT__SMS_2' onClick={() => handleVerify(code)} disabled={!(code.length === VERIFY_CODE_LENGTH) || !!smsVerification.errorMessage} loading={isVerifyingCode} data-sentry-element="Button" data-sentry-source-file="VerifyCodeStep.tsx">
          {t('SIGNUP__NEXT')}
        </Button>
      </ModalFooter>
    </>;
};
export default SmsVerificationVerifyCodeStep;