import { DynamicRoute, StaticRoute } from 'nextjs-routes';

import store from 'src/stores';
import { nextPagePropsAtom } from 'src/stores/pageProps/atoms';
import { normalizeStringForURL } from 'src/utils/blog';
import { getBlogLanguage } from 'src/utils/blog/language';

import {
  DefaultLocaleRoute,
  DynamicRoutes,
  ExtractPathnameString,
  ExtractQuery,
  LocaleRoute,
  NonLocaleRoute,
} from './types';

/**
 * getCurrentLocaleRoute의 ReturnType을 고유한 타입으로 한정하기 위해서 Branded 패턴 적용
 */
type Branded<T, B> = T & { __brand: B };
type CurrentLocaleRouteBranded = Branded<DefaultLocaleRoute | LocaleRoute, 'CurrentLocaleRoute'>;

function getCurrentLocaleRoute<K extends ExtractPathnameString<DefaultLocaleRoute>>(
  staticLocaleRoutePathname: K,
  queryParam?: Record<string, unknown>
) {
  const locale = store.get(nextPagePropsAtom)?.staticGenerationLanguage;

  const result = locale
    ? {
        pathname: `/[locale]${staticLocaleRoutePathname}`,
        query: { locale, ...queryParam },
      }
    : {
        pathname: staticLocaleRoutePathname,
        query: queryParam,
      };

  return result as CurrentLocaleRouteBranded;
}

/**
 * key는 [locale]이 없는 root route를 사용합니다.
 * value는 현재 locale에 따라 적절한 locale route 혹은 root route를 반환합니다.
 */
const LocaleRoutes = {
  '/': function () {
    return getCurrentLocaleRoute('/');
  },
  '/about': function () {
    return getCurrentLocaleRoute('/about');
  },
  '/history': function () {
    return getCurrentLocaleRoute('/history');
  },
  '/live': function () {
    return getCurrentLocaleRoute('/live');
  },
  '/live/host': function ({ hostId }: { hostId: string }) {
    return getCurrentLocaleRoute('/live/host', {
      hostId,
    });
  },
  '/live/publish': function () {
    return getCurrentLocaleRoute('/live/publish');
  },
  '/blog': function () {
    return getCurrentLocaleRoute('/blog');
  },
  '/blog/[sectionName]/[articleTitle]': function ({
    sectionName,
    articleTitle,
    queryParam,
  }: {
    sectionName: string;
    articleTitle: string;
    queryParam?: Record<string, unknown>;
  }) {
    return getCurrentLocaleRoute('/blog/[sectionName]/[articleTitle]', {
      languageCode: getBlogLanguage(),
      sectionName: normalizeStringForURL(sectionName),
      articleTitle: normalizeStringForURL(articleTitle),
      ...queryParam,
    });
  },
} satisfies {
  [K in ExtractPathnameString<DefaultLocaleRoute>]: (...arg: any[]) => CurrentLocaleRouteBranded;
};

const NonLocaleRoutes = {
  '/404': function () {
    return { pathname: '/404' };
  },
  '/data-request': function () {
    return { pathname: '/data-request' };
  },
  '/download': function () {
    return { pathname: '/download' };
  },
  '/dynamic_link_live': function () {
    return { pathname: '/dynamic_link_live' };
  },
  '/event': function () {
    return {
      pathname: '/event',
    };
  },
  '/license': function () {
    return { pathname: '/license' };
  },
  '/payment': function () {
    return { pathname: '/payment' };
  },
  '/payments_redirect': function () {
    return { pathname: '/payments_redirect' };
  },
  '/policy': function () {
    return { pathname: '/policy' };
  },
  '/stripe_payments_success': function () {
    return { pathname: '/stripe_payments_success' };
  },
  '/terms': function () {
    return { pathname: '/terms' };
  },
  '/toss_payments_failure': function () {
    return { pathname: '/toss_payments_failure' };
  },
  '/toss_payments_success': function () {
    return { pathname: '/toss_payments_success' };
  },
  '/vip_rating': function () {
    return { pathname: '/vip_rating' };
  },
} satisfies {
  [K in ExtractPathnameString<NonLocaleRoute>]: (
    ...args: any[]
  ) => K extends ExtractPathnameString<DynamicRoutes>
    ? DynamicRoute<K, ExtractQuery<K>>
    : StaticRoute<K>;
};

export const Routes = {
  ...LocaleRoutes,
  ...NonLocaleRoutes,
};
