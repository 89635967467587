import { FC, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import Button from 'src/components/Button';
import InputBase from 'src/components/Input';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { LoadingBackground, LoginModalHeader, Wrap } from 'src/components/LoginModal/styles';
import { ModalFooter, ModalTitle } from 'src/components/ModalLayout';
import Select from 'src/components/Select';
import SMSRestrictedModal from 'src/components/SMSRestrictedModal';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import useSendSmsVerificationCode from 'src/hooks/useSendSmsVerificationCode';
import useVerifyPreparation from 'src/hooks/useVerifyPreparation';
import { nextSmsVerificationStepAtom, smsPhoneNumberAtom } from 'src/stores/phoneNumberLogin/atoms';
import { DropdownItem } from 'src/types/DateSelector';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ErrorResponse } from 'src/utils/api';
import { isAxiosError } from 'src/utils/error';
const SelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({
  theme
}) => theme.screenSize.mobile} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
const CountrySelect = styled(Select)`
  width: 100%;
  margin-bottom: 12px;
  ${({
  theme
}) => theme.isRtl ? 'margin-left' : 'margin-right'}: 8px;
  ${({
  theme
}) => theme.screenSize.mobile} {
    margin-bottom: 8px;
    display: none;
  }
` as typeof Select;
const DescWrap = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
`;
const Desc = styled.p`
  font-size: 12px;
  line-height: 18px;

  color: ${({
  theme
}) => theme.color.gray500__dkGray400};
  margin-top: 16px;
  ${({
  theme
}) => theme.screenSize.mobile} {
    margin-top: 0;
  }
`;
const WarningWrap = styled.div`
  display: flex;
  align-items: flex-start;
`;
const WarningIcon = styled.img`
  ${({
  theme
}) => theme.isRtl ? 'margin-left' : 'margin-right'}: 8px;
`;
const WarningText = styled.p`
  font-size: 12px;
  line-height: 18px;

  color: ${({
  theme
}) => theme.color.gray700__dkGray700};
`;
const Form = styled.form`
  flex: 1;
`;
const MobileCountryCode = styled.select`
  display: none;
  outline: none;
  height: min-content;
  ${({
  theme
}) => theme.screenSize.mobile} {
    height: 44px;
    margin-bottom: 10px;
    display: block;
    ${({
  theme
}) => theme.isRtl ? 'margin-left' : 'margin-right'}: 8px;
    padding: 0px;
    padding-bottom: 4px;
    border: none;
    border-radius: 0;

    border-bottom: 2px solid ${({
  theme
}) => theme.color.gray700__dkGray700};
    background-color: transparent;
    font-weight: 700;
    font-size: 24px;
    width: 70px;
    color: ${({
  theme
}) => theme.color.green500};
    ::placeholder {
      color: ${({
  theme
}) => theme.color.gray200__dkGray200};
      opacity: 1;
    }
    :focus {
      border: none;
      border-bottom: 2px solid ${({
  theme
}) => theme.color.gray700__dkGray700};
    }
  }
`;
const Input = styled(InputBase)`
  ${({
  theme
}) => theme.screenSize.mobile} {
    min-height: 44px;
  }
`;
const SmsVerificationPhoneNumberStep: FC = () => {
  const {
    t
  } = useTranslation();
  const [smsPhoneNumber, setSmsPhoneNumber] = useAtom(smsPhoneNumberAtom);
  const nextSmsVerificationStep = useSetAtom(nextSmsVerificationStepAtom);
  const setPhone = useCallback((phone: string) => {
    setSmsPhoneNumber({
      ...smsPhoneNumber,
      phone,
      errorMessage: ''
    });
  }, [smsPhoneNumber, setSmsPhoneNumber]);
  const {
    send: sendSmsVerificationCode,
    isLoading: isSendingSmsVerificationCode
  } = useSendSmsVerificationCode();
  const device = useCssMediaDevice();
  const pushEvent = useEvent();
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPENED__PHONE_NUMBER
    });
  }, [pushEvent]);
  const handleClickNext = async () => {
    if (smsPhoneNumber.verifyPreparation.countryPrefixNumber) {
      try {
        await sendSmsVerificationCode();
        nextSmsVerificationStep();
      } catch (error) {
        if (!isAxiosError<ErrorResponse>(error)) return;
        if (error.response?.data.error.code === 'TURNSTILE_REQUIRED') {
          nextSmsVerificationStep();
        }
      }
    }
  };
  const {
    options,
    prefixList,
    isError: isPreparationError,
    isFetching: isPreparationLoading
  } = useVerifyPreparation();
  const handleChangeCountry = (v: DropdownItem | null) => {
    setSmsPhoneNumber({
      ...smsPhoneNumber,
      verifyPreparation: prefixList.find(prefix => prefix.countryName === v?.value) || prefixList[0]
    });
  };
  const handleMobileChangeCountry = (v: React.ChangeEvent<HTMLSelectElement>) => {
    setSmsPhoneNumber({
      ...smsPhoneNumber,
      verifyPreparation: prefixList.find(prefix => prefix.countryName === v.target.value) || prefixList[0]
    });
  };
  const onChangePhone = (text: string) => {
    if (!isNaN(Number(text))) {
      setPhone(text);
    }
  };
  if (isPreparationLoading) {
    return <LoadingBackground>
        <LoadingIndicator size={18} />
      </LoadingBackground>;
  }
  if (isPreparationError) {
    return <SMSRestrictedModal />;
  }
  return <>
      <Wrap data-sentry-element="Wrap" data-sentry-source-file="PhoneNumberStep.tsx">
        <LoginModalHeader data-testid='LoginModal-PhoneNumberStep' data-sentry-element="LoginModalHeader" data-sentry-source-file="PhoneNumberStep.tsx">
          <ModalTitle data-sentry-element="ModalTitle" data-sentry-source-file="PhoneNumberStep.tsx">{t('SIGNUP_PHONE_TITLE')}</ModalTitle>
        </LoginModalHeader>
        <SelectWrap data-sentry-element="SelectWrap" data-sentry-source-file="PhoneNumberStep.tsx">
          <CountrySelect absolute isSearchable={false} label={t('SIGNUP_PHONE_COUNTRY_PLACEHOLDER')} options={options} onChange={handleChangeCountry} data-testid='LoginModal-PhoneNumberStep-CountrySelect-Desktop' value={options.find(option => option.value === smsPhoneNumber.verifyPreparation.countryName)} data-sentry-element="CountrySelect" data-sentry-source-file="PhoneNumberStep.tsx" />
          <MobileCountryCode onChange={handleMobileChangeCountry} value={smsPhoneNumber.verifyPreparation.countryName} data-testid='LoginModal-PhoneNumberStep-CountrySelect-Mobile' onBlur={() => {}} data-sentry-element="MobileCountryCode" data-sentry-source-file="PhoneNumberStep.tsx">
            {options.map(item => <option value={item.value} key={item.value} label={item.label.split(' ')[0]}>
                {item.label.split(' ')}
              </option>)}
          </MobileCountryCode>
          <Form onSubmit={e => {
          e.preventDefault();
          handleClickNext();
        }} data-sentry-element="Form" data-sentry-source-file="PhoneNumberStep.tsx">
            <Input data-testid='LoginModal-PhoneNumberStep-input' placeholder={t('SIGNUP_PHONE_PLACEHOLDER')} type='number' pattern='\d*' value={smsPhoneNumber.phone} errorMessage={smsPhoneNumber?.errorMessage} onChangeText={onChangePhone} autoFocus={device !== 'mobile'} data-sentry-element="Input" data-sentry-source-file="PhoneNumberStep.tsx" />
          </Form>
        </SelectWrap>
        {!smsPhoneNumber?.errorMessage && <DescWrap>
            <Desc>{t('SIGNUP_PHONE_INPUT_DESC')}</Desc>
            <WarningWrap>
              <WarningIcon src='/images/icons/icWarning.svg' alt='warning' />
              <WarningText>{t('SIGNUP_PHONE_NUMBER_GUIDE')}</WarningText>
            </WarningWrap>
          </DescWrap>}
      </Wrap>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="PhoneNumberStep.tsx">
        <Button id='SIGNUP__NEXT__SMS_1' onClick={handleClickNext} loading={isSendingSmsVerificationCode} data-testid='LoginModal-PhoneNumberStep-next' disabled={smsPhoneNumber.phone.length < 3 || !!smsPhoneNumber?.errorMessage} data-sentry-element="Button" data-sentry-source-file="PhoneNumberStep.tsx">
          {t('SIGNUP__NEXT')}
        </Button>
      </ModalFooter>
    </>;
};
export default SmsVerificationPhoneNumberStep;