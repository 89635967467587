import { ReactElement } from 'react';
import * as Sentry from '@sentry/nextjs';
import { atom } from 'jotai';
import { atomWithReset, loadable, RESET } from 'jotai/utils';
import RegisterGuidelineModal from 'src/components/LoginModal/GuidelineModal';
import SmsVerificationPhoneNumberStep from 'src/components/LoginModal/Steps/SmsVerification/PhoneNumberStep';
import { acceptCmpConsentsAtom, getCmpConsentsAtom, handleLoginResultAtom, socialTokenAtom, tryLoginTypeAtom, userJoinDateAtom } from 'src/stores/auth/atoms';
import { eventMutateAtom } from 'src/stores/event/atoms';
import { statusAtom } from 'src/stores/match/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { isRemoveOldDeviceUserDetailsAtom, phoneNumberLoginEmailAtom, smsPhoneNumberAtom, smsVerificationAtom } from 'src/stores/phoneNumberLogin/atoms';
import { setFeatureSettingsAtom } from 'src/stores/user/atom';
import { SetFeatureSettings, SetFeatureSettingsParam } from 'src/stores/user/types';
import { ConsentsMapRequest } from 'src/types/consent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { STATUS } from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import { GENDER, LoginType, RegisterParamsBase, SignUpSettingsResponse } from 'src/types/register';
import getDeviceId from 'src/utils/device/id';
import { getSignUpSettingsAPI, registerOAuthAPI, saveEmailAPI } from './apis';
export const defaultBirth = (() => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
})();
export const

  // 전화번호 로그인 및 회원가입. + SNS 로그인 시 2차 인증이 필요한 경우. 추후 분리하여 개선.
enum LoginModalType {
  LOGIN_ENTRY,
  // 최초 모달
  SOCIAL_LOGIN,
  // SNS 로그인(email 인증이 필요한 경우)
  SOCIAL_REGISTER,
  // SNS 회원가입
  PHONE_NUMBER_LOGIN,
}
export const loginModalTypeAtom = atom<LoginModalType>(LoginModalType.LOGIN_ENTRY);
export const isRegisterLoadingAtom = atom(false);
export const isRegisterCompleteAtom = atomWithReset(false);
export const birthAtom = atom(new Date(defaultBirth));
export const genderAtom = atom<GENDER | undefined>(undefined);
export const socialRegisterHistoryAtom = atomWithReset<JSX.Element[]>([]);
export const pushSocialRegisterHistoryAtom = atom(null, (get, set, nextStep: ReactElement) => {
  set(socialRegisterHistoryAtom, history => [...history, nextStep]);
});
export const popSocialRegisterHistoryAtom = atom(null, (get, set) => {
  set(socialRegisterHistoryAtom, history => history.slice(0, -1));
});
export const featureSettingParamsFromOptionalConsentsAtom = atomWithReset<SetFeatureSettings[] | null>(null);
export const requiredConsentsMapForRegisterAtom = atomWithReset<ConsentsMapRequest['consents'] | null>(null);
export const legalNoticeMapForRegisterAtom = atomWithReset<RegisterParamsBase['legalNoticesConfirmed'] | null>(null);
export const resetRegisterAtom = atom(null, (get, set) => {
  set(featureSettingParamsFromOptionalConsentsAtom, RESET);
  set(requiredConsentsMapForRegisterAtom, RESET);
  set(statusAtom, STATUS.INITIAL);
  set(socialRegisterHistoryAtom, RESET);
  set(loginModalTypeAtom, LoginModalType.LOGIN_ENTRY);
  set(isRemoveOldDeviceUserDetailsAtom, false);
  set(smsPhoneNumberAtom, RESET);
  set(isRegisterCompleteAtom, RESET);
});
export const registerAtom = atom(null, async (get, set, params: {
  gender?: GENDER;
  childGender?: string;
  phoneVerification?: {
    verifyCode: string;
    formattedPhoneNumber: string;
  };
  featureSettings?: SetFeatureSettingsParam['featureSettings'];
  cmpConsentsAccepted: boolean;
}) => {
  set(isRegisterLoadingAtom, true);
  const loginType = get(tryLoginTypeAtom);
  const socialToken = get(socialTokenAtom);
  const birth = get(birthAtom);
  const gender = params.gender || get(genderAtom);
  if (params.gender) {
    set(genderAtom, params.gender);
  }
  if (loginType === undefined || !socialToken || !birth || !gender) {
    return;
  }
  const requestBody = {
    socialToken,
    birthYear: birth.getFullYear(),
    birthMonth: birth.getMonth() + 1,
    birthDay: birth.getDate(),
    ...params,
    gender,
    childGender: params.childGender,
    consents: get(requiredConsentsMapForRegisterAtom) ?? {},
    legalNoticesConfirmed: get(legalNoticeMapForRegisterAtom) ?? {}
  };
  const response = await registerOAuthAPI(loginType, requestBody);
  try {
    const {
      data: {
        result: userData,
        error
      }
    } = response;
    if (userData && !error) {
      await set(handleLoginResultAtom, response);
      if (userData.accessToken) {
        if (loginType === LoginType.SMS) {
          await saveEmailAPI({
            email: get(phoneNumberLoginEmailAtom).email
          });
        }
        if (userData.accessToken) {
          set(isRegisterCompleteAtom, true);
        }
        const featureSettings = [...(params.featureSettings ?? []), ...(get(featureSettingParamsFromOptionalConsentsAtom) ?? [])];
        const results = await Promise.allSettled([params.cmpConsentsAccepted ? set(acceptCmpConsentsAtom) : set(getCmpConsentsAtom), featureSettings.length > 0 && set(setFeatureSettingsAtom, featureSettings)]);
        results.forEach(result => {
          if (result.status === 'rejected') {
            Sentry.captureException(result.reason);
          }
        });
      }
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.SIGN_UP,
        eventName: EVENT_NAME.SIGNUP_COMPLETE,
        eventParams: {
          userId: userData.userId,
          loginType: get(tryLoginTypeAtom)
        },
        options: {
          user_id: userData.userId
        }
      });
      set(userJoinDateAtom, new Date());
      set(resetRegisterAtom);
      set(showModalAtom, {
        key: ModalType.REGISTER_GUIDELINE,
        component: RegisterGuidelineModal
      });
    } else if (error) {
      if (error.message === 'NotVerifiedException') {
        // 2차 인증이 필요한 국가의 경우 SmsVerificationStep을 진행
        set(pushSocialRegisterHistoryAtom, <SmsVerificationPhoneNumberStep />);
      } else if (error.message === 'VerificationFailedException') {
        // 2차 인증의 인증코드가 틀렸을 경우
        set(smsVerificationAtom, smsVerification => ({
          ...smsVerification,
          errorMessage: 'SIGNUP_PHONE_VERIFICATION_FAIL'
        }));
      } else {
        Sentry.captureEvent({
          message: 'Unexpected Register Error Message',
          extra: {
            error: JSON.stringify(error),
            loginType,
            requestBody: JSON.stringify({
              ...requestBody,
              socialToken: ''
            }).replace('socialToken', ''),
            dd: getDeviceId()
          }
        });
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  } finally {
    set(isRegisterLoadingAtom, false);
  }
});
export const FALLBACK_SIGNUP_SETTINGS: SignUpSettingsResponse = {
  genderSettings: {
    nonBinaryChildGenders: [],
    nonBinaryEnabled: false,
    hideGenderOptionEnabled: false,
    hideGenderDefaultValue: false
  },
  birthdaySettings: {
    blockAlertAge: 1,
    uiType: 'BIRTHDAY_WEB_B'
  }
};
export const fetchSignUpSettingsAtom = atom<Promise<SignUpSettingsResponse> | SignUpSettingsResponse>(async () => {
  const {
    data
  } = await getSignUpSettingsAPI();
  return data;
});
export const signUpSettingsLoadableAtom = loadable(fetchSignUpSettingsAtom);