import styled from '@emotion/styled';

import { ModalHeaderDescriptionRow } from 'src/components/ModalLayout';
import ModalLayoutBase from 'src/components/ModalLayout';

export const ModalLayout = styled(ModalLayoutBase)`
  max-width: 500px;
  ${({ theme }) => theme.screenSize.desktop} {
    max-width: 400px;
  }
`;

export const Desc = styled(ModalHeaderDescriptionRow)`
  color: ${({ theme }) => theme.color.gray700__dkGray700};
  a {
    color: ${({ theme }) => theme.color.gray700__dkGray700};
  }
`;
