import { useCallback, useEffect, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { UpdatedTosApprovalModal } from 'src/components/LegalUpdateModal';
import useLogin from 'src/hooks/useLogin';
import { userDataAtom } from 'src/stores/auth/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { OrderedModalHookType } from './types';
export const useUpdatedConsentModal: OrderedModalHookType = () => {
  const userData = useAtomValue(userDataAtom);
  const showModal = useSetAtom(showModalAtom);
  const {
    isLogin
  } = useLogin();
  const [isResolved, setIsResolved] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);
  useEffect(() => {
    if (!shouldShow) return;

    // 유저가 방금 sign-up한 경우 약관 갱신 체크할 필요 없음
    if (isLogin && userData?.userSignedUp) {
      setIsResolved(true);
      return;
    }
    const updatedConsentWithPopup = userData?.consentsRequired?.find(consent => consent.consentRevisionPopup);
    if (!updatedConsentWithPopup) {
      setIsResolved(true);
      return;
    }

    // consent가 mutable하기 때문에 조건문으로 consent에서 바로 검사 후 props 전달 시 타입에러 발생
    const {
      consentRevisionPopup,
      ...rest
    } = updatedConsentWithPopup;
    if (!consentRevisionPopup) {
      setIsResolved(true);
      return;
    }
    showModal({
      key: ModalType.TOS_RE_APPROVAL,
      component: () => <UpdatedTosApprovalModal consent={{
        ...rest,
        consentRevisionPopup
      }} />
    });
  }, [isLogin, showModal, userData?.userSignedUp, userData?.consentsRequired, shouldShow]);
  useEffect(() => {
    // 로그아웃 시, isResolved를 false로 초기화
    if (!isLogin) {
      setIsResolved(false);
    }
  }, [isLogin]);
  return {
    isResolved,
    show: useCallback(() => setShouldShow(true), [])
  };
};