import { type ComponentProps, useRef } from 'react';
import styled from '@emotion/styled';
import { dateOptions, monthOptions, yearOptions } from 'src/types/DateSelector';
import { hexToRgb } from 'src/utils/common';
import { getLocaleDatePattern } from 'src/utils/date';
import WheelPicker, { WheelOption } from './Wheel';
const Wrapper = styled.div`
  position: relative;

  display: flex;

  border-top: 1px solid ${({
  theme
}) => theme.color.gray100__dkGray150};
  border-bottom: 1px solid ${({
  theme
}) => theme.color.gray100__dkGray150};
`;
const WheelWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const WheelOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  ${({
  theme: {
    color: {
      gray900
    }
  }
}) => `
      background: linear-gradient(${gray900}, rgb(${hexToRgb(gray900)}, 0) 52%, rgba(${hexToRgb(gray900)}, 0) 48%,${gray900});
    `}
`;
interface Props extends Pick<ComponentProps<'div'>, 'className'> {
  value: Date;
  onChange: (v: Date) => void;
}
export function DatePicker({
  value,
  onChange,
  ...props
}: Props) {
  const datePatternRef = useRef(getLocaleDatePattern());
  const handleChangeYear = (v: WheelOption) => {
    const nextDate = new Date(value);
    nextDate.setFullYear(parseInt(v.value, 10));
    onChange(nextDate);
  };
  const handleChangeMonth = (v: WheelOption) => {
    const nextDate = new Date(value);
    const newMonth = parseInt(v.value, 10);
    const lastDayOfMonth = new Date(nextDate.getFullYear(), newMonth + 1, 0).getDate();
    const currentDay = nextDate.getDate();
    nextDate.setDate(Math.min(currentDay, lastDayOfMonth));
    nextDate.setMonth(newMonth);
    onChange(nextDate);
  };
  const handleChangeDate = (v: WheelOption) => {
    const nextDate = new Date(value);
    const newDay = parseInt(v.value, 10);
    const lastDayOfMonth = new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 0).getDate();
    nextDate.setDate(Math.min(newDay, lastDayOfMonth));
    onChange(nextDate);
  };
  return <Wrapper {...props} data-sentry-element="Wrapper" data-sentry-component="DatePicker" data-sentry-source-file="index.tsx">
      <WheelOverlay className='wheel-overlay' data-sentry-element="WheelOverlay" data-sentry-source-file="index.tsx" />
      {datePatternRef.current.map(pattern => {
      switch (pattern) {
        case 'MM':
          return <WheelWrapper>
                <WheelPicker onChange={handleChangeMonth} value={monthOptions.find(({
              value: optionValue
            }) => optionValue === `${value.getMonth()}`)} options={monthOptions} />
              </WheelWrapper>;
        case 'DD':
          return <WheelWrapper>
                <WheelPicker onChange={handleChangeDate} value={dateOptions.find(({
              value: optionValue
            }) => optionValue === `${value.getDate()}`)} options={dateOptions} />
              </WheelWrapper>;
        case 'YEAR':
          return <WheelWrapper>
                <WheelPicker onChange={handleChangeYear} value={yearOptions.find(({
              value: optionValue
            }) => optionValue === `${value.getFullYear()}`)} options={yearOptions} />
              </WheelWrapper>;
      }
    })}
    </Wrapper>;
}