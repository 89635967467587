import { useCallback, useMemo } from 'react';

import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';

import { useRestMutation } from 'src/hooks/useRestMutation';
import { statusAtom } from 'src/stores/match/atoms';
import { STATUS } from 'src/types/Match';

import { SelectTabRequest, TAB_CONFIGS, TabConfig } from './types';

export enum TabApiList {
  SelectTab = '/api/v1/select-tab',
}

const DEFAULT_TAB = TAB_CONFIGS[0];

interface SelectedTab {
  /**
   * UI상의 탭
   */
  selectedTab: TabConfig;
  /**
   * 서버에서 인지하고 있는 탭 이름
   */
  serverSideTabName: SelectTabRequest['tabName'];
  /**
   * 서버에 현재 탭을 전송
   * 현재 탭 관련하여 필요한 동작이 있는 경우 서버에서 브로커 메시지로 정보 전달
   */
  reportSelectedTab: () => void;
}

const useSelectedTab = (): SelectedTab => {
  const { pathname } = useRouter();
  const status = useAtomValue(statusAtom);

  const { mutate } = useRestMutation<SelectTabRequest>({
    path: TabApiList.SelectTab,
    method: 'post',
  });

  const tab = useMemo(
    () => TAB_CONFIGS.find((t) => (t.pathnames as string[]).includes(pathname)) || DEFAULT_TAB,
    [pathname]
  );

  const serverSideTabName: SelectTabRequest['tabName'] = useMemo(() => {
    switch (tab.name) {
      case 'mirror':
        return status === STATUS.INITIAL ? 'MIRROR' : 'MATCH';
      case 'live':
        return 'LIVE';
      case 'history':
        return 'HISTORY';
      case 'about':
        return 'ABOUT';
      case 'blog':
        return 'BLOG';
    }
  }, [status, tab.name]);

  const reportSelectedTab = useCallback(() => {
    mutate({
      tabName: serverSideTabName,
    });
  }, [mutate, serverSideTabName]);

  return {
    reportSelectedTab,
    selectedTab: tab,
    serverSideTabName,
  };
};

export default useSelectedTab;
