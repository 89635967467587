import { createRef } from 'react';

import { VideoCallStats } from '@hyperconnect/media-metric';
import * as Sentry from '@sentry/nextjs';
import { IMessage } from '@stomp/stompjs';
import { addDays, differenceInSeconds, isPast } from 'date-fns';
import { atom } from 'jotai';
import { atomWithReset, RESET, selectAtom } from 'jotai/utils';
import { i18n } from 'next-i18next';
import { concatMap, exhaustMap } from 'rxjs/operators';

import VirtualVideoErrorModal from 'src/components/AzarOnly/Match/Matching/VirtualVideoErrorModal';
import GemLackModal from 'src/components/GemLackModal';
import MatchStarterPackageNudgingModal from 'src/components/StarterPackageNudgingModal/MatchStarterPackageNudgingModal';
import SuspensionModal from 'src/components/SuspensionModal';
import {
  getInventoryAtom,
  remoteConfigAtom,
  userDataAtom,
  userInventoryAtom,
  userJoinDateAtom,
} from 'src/stores/auth/atoms';
import { decoVidRefAtom, userEffectSettingAtom } from 'src/stores/deco/atom';
import { eventMutateAtom } from 'src/stores/event/atoms';
import { headerAtom } from 'src/stores/header/atoms';
import {
  antmanConfigAtom,
  antmanEnableAtom,
  antmanSuspendedAtom,
  startAntmanAtom,
} from 'src/stores/ml/atoms';
import { MLInference, MLModelType } from 'src/stores/ml/types';
import { closeModalAtom, openedModalsAtom, showModalAtom } from 'src/stores/modal/atoms';
import { lastStompMessageAtom, stompAtom } from 'src/stores/stomp/atoms';
import { showToastAtom } from 'src/stores/toast/atoms';
import { screenWidth } from 'src/styles/screenSize';
import { InventoryItem } from 'src/types/AzarUser';
import { Chat } from 'src/types/Chat';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { GEM_LACK_TRIGGER } from 'src/types/gemLack';
import {
  MatchBrokerMessage,
  ChatMsgMessage,
  MatchConfig,
  MatchFilter,
  MatchFilterSelection,
  MatchInfo,
  MatchInfoMessage,
  MatchOptions,
  MLMatchMetrics,
  RandomMatchContext,
  ReportMatchMethod,
  SignalingInfo,
  STATUS,
  PreviousMatchSummary,
  ContentsMatchMessage,
} from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import { ReportType } from 'src/types/report';
import { ToastType } from 'src/types/Toast';
import { ErrorResponse, PunishedException } from 'src/utils/api';
import { atomWithPipes } from 'src/utils/atom';
import { deepEqual, isObjectKeyTrue } from 'src/utils/common';
import { uploadContentManager } from 'src/utils/content-manager';
import { isAxiosError } from 'src/utils/error';
import { getAtomWithStorage } from 'src/utils/localStorageUtils';
import { createMatchUuid } from 'src/utils/match';
import AudioLevelGetter, { getTargetAudioLevel } from 'src/utils/media/audio-level';
import { dataURItoBlob, isImageFileEmpty } from 'src/utils/media/blob';
import { isValidCandidate } from 'src/utils/media/munge_sdp';
import MediaStreamPipeManager from 'src/utils/media/pipes';
import { AntmanResultResponse } from 'src/utils/media/pipes/antman';
import EffectRendererPipe from 'src/utils/media/pipes/effect';
import RotateMediaStream from 'src/utils/media/pipes/rotate';
import { SilentAudioPlayer } from 'src/utils/media/silent-audio-player';
import { clearStream } from 'src/utils/media/stream';
import { attachMediaElement, detachMediaElement } from 'src/utils/media/view';
import { getUserGemsAmount } from 'src/utils/user';
import { PeerConnectionClient } from 'src/utils/webrtc';
import { printVideoCallStats } from 'src/utils/webrtc/bridge';
import { IceServerManager } from 'src/utils/webrtc/ice-server-manager';
import { MatchStat } from 'src/utils/webrtc/stat';

import {
  abortMatchAPI,
  CameraInMatchInfo,
  cancelMatchRandomAPI,
  listMatchFiltersAPI,
  matchRandomAPI,
  reportContentsMatchMetricsAPI,
  reportContentsMatchStartAPI,
  ReportMatchAPI,
  reportMatchMetricsAPI,
  ReportMatchParams,
  reportMatchStartAPI,
  reportMLMatchInferencesAPI,
  reportMLMatchMetricsAPI,
  reportMLMatchSummaryAPI,
  sendTextChatAPI,
  sendWebRTCStatInfoV1API,
} from './apis';

// import VitarStream from 'src/utils/media/pipes/vitar';

export const isShowingCoachMarkAtom = atom(false);

// Storage
export const matchFiltersAtom = getAtomWithStorage<MatchFilter[]>('matchFiltersV2', []);
const isConsumableUsable = (item: InventoryItem, consumableCategoryId?: string) => {
  return item.categoryId === consumableCategoryId && item.quantity > 0;
};

const isPeriodicUsable = (item: InventoryItem, periodicCategoryId?: string) => {
  const today = new Date();
  return (
    item.categoryId === periodicCategoryId &&
    item.dateValidUntil &&
    new Date(item.dateValidUntil) >= today
  );
};

export const getRequiredGemFromMatchFilterAtom = atom(
  null,
  (get, _set, updatedItems: MatchFilterSelection[]) => {
    const matchFilterSelections = updatedItems;
    const matchFilters = get(matchFiltersAtom);
    const userInventory = get(userInventoryAtom);

    const requiredGemAmount = matchFilterSelections.reduce((acc, selection) => {
      const { filterId, optionGemCost, optionKey } = selection;

      const filter = matchFilters.find((filterItem) => filterItem.filterId === filterId);

      const consumableCategoryId = filter?.consumableCategoryId;
      const periodicCategoryId = filter?.periodicCategoryId;

      const canUseItem = userInventory.some(
        (item) =>
          isConsumableUsable(item, consumableCategoryId) ||
          isPeriodicUsable(item, periodicCategoryId)
      );

      // LOCAL_PAID는 아이템 적용 불가함
      if (canUseItem && optionKey !== 'LOCAL_PAID') {
        return acc;
      }

      return acc + optionGemCost;
    }, 0);

    return requiredGemAmount;
  }
);

export const initialMatchFilterSelections = [
  {
    filterId: 'GENDER_CHOICE',
    optionKey: 'ALL',
    optionGemCost: 0,
  },
  {
    filterId: 'REGION_CHOICE',
    optionKey: 'DEFAULT',
    optionGemCost: 0,
  },
];
export const matchFilterSelectionsAtom = getAtomWithStorage<MatchFilterSelection[]>(
  'matchFilterSelectionsV2',
  initialMatchFilterSelections
);

export const isPremiumAtom = selectAtom(
  matchFilterSelectionsAtom,
  (matchFilterSelections) => matchFilterSelections.some(({ optionGemCost }) => optionGemCost > 0),
  deepEqual
);
export const totalMatchTimeSecAtom = getAtomWithStorage('totalMatchTimeSecAtom', 0);

// stable
export const vidRefAtom = atom(createRef<HTMLCanvasElement>());
export const peerVidRefAtom = atom(createRef<HTMLCanvasElement>());
export const matchOptionsAtom = getAtomWithStorage<MatchOptions>('matchOptions', {
  // PREFERRED_GENDER: 'ALL',
  PREFERRED_PLATFORM: null,
  MATCH_GROUP: 'DEFAULT',
  // preferredAge: 'BOTH',
  ...(process.env.NEXT_PUBLIC_ENVIRONMENT !== 'Prod' && { MATCH_TAG: null }),
});

// clear when leave page
export const stepAtom = atom(1);
export const statusAtom = atom(STATUS.INITIAL);
export const sourceStreamAtom = atom<MediaStream | null>(null); // 원본 media stream 보관

// clear when disconnect

export const localVideoToViewAtom = atom<MediaStreamPipeManager | null>(null); // local에 rendering할 stream
export const localVideoToSendAtom = atom<MediaStreamPipeManager | null>(null); // peer에게 전송할 local stream
export const localVideoToDecoViewAtom = atom<MediaStreamPipeManager | null>(null); // deco에 rendering할 stream

export const localVideoStreamAtom = atom<MediaStream | null>(null);
export const isOnCameraAtom = atom(true);
export const peerVideoAtom = atom<MediaStreamPipeManager | null>(null); // peer로부터 받은 remote stream 을 transform
export const peerAudAtom = atom<HTMLAudioElement | null>(null); // peer로부터 받은 원본 audio track 보관
export const peerConnectionAtom = atom<PeerConnectionClient | null>(null);
export const matchInfoAtom = atom<MatchInfo | void>(undefined);
export const chatListAtom = atom<Chat[]>([]);
export const peerAudioLevelAtom = atom<number>(0);
export const peerAudioLevelGetterAtom = atom<AudioLevelGetter | void>(undefined);
export const localAudioLevelAtom = atom<number>(0);
export const localAudioLevelGetterAtom = atom<AudioLevelGetter | void>(undefined);

const ML_INFERENCE_REPORT_INTERVAL_MS = 60000;

const mlInferenceReportTimerAtom = atom<ReturnType<typeof setInterval> | null>(null);
const antmanInferenceReportIndexAtom = atomWithReset(0);
const antmanInferenceQueueAtom = atomWithReset<MLInference[]>([]);
const antmanLatencySumAtom = atomWithReset(0);
const antmanInferenceCountAtom = atomWithReset(0);
export const antmanResultAtom = atomWithReset<AntmanResultResponse | null>(null);
export const antmanCapturedAtom = atomWithReset<{
  file: Blob;
  triggeredAt: number;
  inferenceIndex: number;
  handled: boolean;
} | null>(null);

export const previousMatchSummaryAtom = atom<PreviousMatchSummary | null>(null);
export const matchConfigAtom = atom<MatchConfig | void>(undefined);
export const contentsMatchConfigAtom = atom<ContentsMatchMessage | null>(null);
export const isBonusMatchAtom = selectAtom(
  matchConfigAtom,
  (matchConfig) => !!matchConfig?.bonusMatchStatus?.bonusMatch
);
export const signalingInfoAtom = selectAtom(
  matchConfigAtom,
  (matchConfig) => matchConfig?.signalingInfo,
  deepEqual
);
export const matchStatAtom = atom<MatchStat | void>(undefined);
export const videoCallStatAtom = atomWithReset<VideoCallStats | void>(undefined);
export const debugStatAtom = selectAtom(videoCallStatAtom, (videoCallStat) => {
  if (!videoCallStat) return null;
  return printVideoCallStats(videoCallStat);
});
export const MLMetricsAtom = atom<MLMatchMetrics['actions']>([]);
export const chatStartDateAtom = atom<Date | null>(null);
export const peerCameraEnabledAtom = atom<boolean>(true);

export const matchRetryTimerAtom = atom<ReturnType<typeof setTimeout> | void>(undefined);
export const matchWaitTimerAtom = atom<ReturnType<typeof setTimeout> | void>(undefined);

export const nextMatchDisableShowAtom = atom(false);
export const endMatchDisableShowAtom = atom(false);
export const swipeDisableSecondAtom = atom(0);
export const swipeDisableDurationAtom = atom(0);
export const swipeDisabledTimerAtom = atom<ReturnType<typeof setInterval> | void>(undefined);

// api 서버에서 주는 matchId는 너무 길어서 서버의 matchId랑 matchUuid:matchId = 2(유저수):1 로 매핑되고 화면에 보여줄 uuid 매칭때마다 생성
export const matchUuidAtom = atom('');

const cameraInMatchInfoAtom = atom<CameraInMatchInfo | null>(null);
const lastCameraChangedTimeInMatchAtom = atom(0);

export const clearSwipeDisabledAtom = atom(null, (get, set) => {
  set(swipeDisableSecondAtom, 0);
  set(swipeDisableDurationAtom, 0);
  set(nextMatchDisableShowAtom, false);
  set(endMatchDisableShowAtom, false);
  const timerId = get(swipeDisabledTimerAtom);
  if (timerId) {
    clearInterval(timerId);
    set(swipeDisabledTimerAtom, undefined);
  }
});

const matchTimeoutCountInARowAtom = atom(0);

export const clearMatchRetryAtom = atom(null, (get, set) => {
  const timerId = get(matchRetryTimerAtom);
  set(matchTimeoutCountInARowAtom, 0);
  if (timerId) {
    clearTimeout(timerId);
    set(matchRetryTimerAtom, undefined);
  }
});

export const clearMatchWaitAtom = atom(null, (get, set) => {
  const timerId = get(matchWaitTimerAtom);
  if (timerId) {
    clearTimeout(timerId);
    set(matchWaitTimerAtom, undefined);
  }
});

const startDisableSecondAtom = atom(null, (get, set) => {
  const swipeDisableDuration = get(swipeDisableDurationAtom);
  if (!swipeDisableDuration) {
    set(clearSwipeDisabledAtom);
  } else if (swipeDisableDuration && !get(swipeDisabledTimerAtom)) {
    set(swipeDisableSecondAtom, swipeDisableDuration);
    set(
      swipeDisabledTimerAtom,
      setInterval(() => {
        const nextSecond = get(swipeDisableSecondAtom) - 1;
        if (nextSecond <= 0) {
          set(clearSwipeDisabledAtom);
        } else {
          set(swipeDisableSecondAtom, nextSecond);
        }
      }, 1000)
    );
  }
});

export const displayDelayTimerAtom = atom<ReturnType<typeof setTimeout> | void>(undefined);

const FALLBACK_SWIPE_DISABLE_DURATION_IN_CONNECTING = 7000;
export const matchWaitAtom = atom(null, (get, set) => {
  set(eventMutateAtom, {
    eventType: EVENT_TYPE.VIDEO_CHAT,
    eventName: EVENT_NAME.MATCH_FINDING__PROFILE,
  });
  const matchInfo = get(matchInfoAtom);
  const matchTimerId = setTimeout(() => {
    if (get(statusAtom) >= STATUS.CONNECTING) return;
    set(statusAtom, STATUS.CONNECTING);
    const matchConfig = get(matchConfigAtom);
    // ice connect 연결안되면 매치 재시도
    const timerId = setTimeout(
      () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        set(handleDisconnectAtom, { reconnect: true, finisher: false });
      },
      Math.min(
        matchConfig?.iceConnectionTimeoutSeconds || 10,
        matchConfig?.matchTimeoutSeconds || 12
      ) * 1000
    );
    set(matchWaitTimerAtom, timerId);
    // connecting에서 프로필만 보고 스와이프 하는 것 막기
    const userData = get(userDataAtom);
    const swipeDisableDuration = get(isPremiumAtom)
      ? 0
      : Math.floor(
          (userData?.featureOptions.iceConnectNoSwipeDurationMs ??
            FALLBACK_SWIPE_DISABLE_DURATION_IN_CONNECTING) / 1000
        );
    set(swipeDisableDurationAtom, swipeDisableDuration);
    set(startDisableSecondAtom);
  }, matchInfo?.peerProfile.displayDelayMs || 0);
  set(displayDelayTimerAtom, matchTimerId);
});

export const clearDisplayDelayAtom = atom(null, (get, set) => {
  const timerId = get(displayDelayTimerAtom);
  if (timerId) {
    clearTimeout(timerId);
    set(displayDelayTimerAtom, undefined);
  }
});

const GENDER_FILTER_ID = 'GENDER_CHOICE';
const OLD_FREE_GENDER_OPTION = 'BOTH';
const NEW_FREE_GENDER_OPTION = 'ALL';
export const getMatchFiltersAtom = atom(null, async (get, set) => {
  try {
    const response = await listMatchFiltersAPI();
    set(matchFiltersAtom, response.data.result);
    const matchFilterSelections = get(matchFilterSelectionsAtom);
    const genderFilter = response.data.result.find(
      (filter) => filter.filterId === GENDER_FILTER_ID
    );
    const bothOption = genderFilter?.filterOptions.find(
      (option) => option.key === OLD_FREE_GENDER_OPTION
    );
    const allOption = genderFilter?.filterOptions.find(
      (option) => option.key === NEW_FREE_GENDER_OPTION
    );
    const genderFilterIndex = matchFilterSelections.findIndex(
      (filter) => filter.filterId === GENDER_FILTER_ID
    );
    if (!genderFilter) return;
    // api 에서 가져온 값에 ALL 이 없는 경우 matchFilterSelections에 BOTH 추가
    if (!allOption && bothOption) {
      const newGenderOption = {
        filterId: GENDER_FILTER_ID,
        optionKey: bothOption.key,
        optionGemCost: bothOption.gemCost,
      };
      if (genderFilterIndex > -1) {
        // ALL 말고 이미 선택된 gender filter가 있는 경우 건너뛰기
        if (matchFilterSelections[genderFilterIndex].optionKey !== NEW_FREE_GENDER_OPTION) {
          return;
        }
        const updatedMatchFilterSelections = matchFilterSelections.map((selection) => {
          return selection.filterId === GENDER_FILTER_ID ? newGenderOption : selection;
        });
        set(matchFilterSelectionsAtom, updatedMatchFilterSelections);
      } else {
        set(matchFilterSelectionsAtom, [...matchFilterSelections, newGenderOption]);
      }
    }
    // BOTH가 선택되어 있지만 api에서 ALL을 내려주는 경우 matchFilterSelections ALL로 변경
    if (
      genderFilterIndex > -1 &&
      matchFilterSelections[genderFilterIndex].optionKey === OLD_FREE_GENDER_OPTION &&
      !!allOption
    ) {
      const updatedMatchFilterSelections = matchFilterSelections.map((selection) => {
        return selection.filterId === GENDER_FILTER_ID
          ? {
              filterId: GENDER_FILTER_ID,
              optionKey: allOption.key,
              optionGemCost: allOption.gemCost,
            }
          : selection;
      });

      set(matchFilterSelectionsAtom, updatedMatchFilterSelections);
    }
  } catch (_e) {
    return;
  }
});

export const getMatchLackingGemAmountAtom = atom(
  null,
  (get, set, matchFilterSelections: MatchFilterSelection[] = get(matchFilterSelectionsAtom)) => {
    const requiredGemAmount = set(getRequiredGemFromMatchFilterAtom, matchFilterSelections);
    const inventoryItems = get(userInventoryAtom);
    const availableGemAmount = getUserGemsAmount(inventoryItems) || 0;
    return requiredGemAmount - availableGemAmount;
  }
);

/**
 * 매치 필터 조합을 기반으로 젬부족 사유를 추출
 * @params matchFilterSelections 검사하려는 매치 필터 조합 (default = 현재 적용된 매치 필터 목록)
 * @params isBypassInventory 현재 보유중인 인벤토리를 배제하고 판단할 지 여부 (default = false)
 */
export const getGemLackTriggersFromMatchFiltersAtom = atom(
  null,
  (
    get,
    set,
    { matchFilterSelections = get(matchFilterSelectionsAtom), isBypassInventory = false } = {}
  ) => {
    if (!isBypassInventory) {
      const requiredGems = set(getMatchLackingGemAmountAtom, matchFilterSelections);

      // 인벤토리 확인해봤을 때 현재 인벤토리로 해당 필터 적용 가능한 경우
      if (requiredGems <= 0) return [];
    }

    const gemLackTriggers = matchFilterSelections
      .filter((selection) => {
        const requiredGem = isBypassInventory
          ? selection.optionGemCost
          : set(getRequiredGemFromMatchFilterAtom, [selection]);
        return requiredGem > 0;
      })
      .map((selection) => {
        switch (selection?.filterId) {
          case 'GENDER_CHOICE':
            return GEM_LACK_TRIGGER.genderFilter;
          case 'REGION_CHOICE':
          case 'COUNTRY_GUARANTEE':
            return GEM_LACK_TRIGGER.locationFilter;
          default:
            return;
        }
      })
      .filter((gemlackTrigger) => gemlackTrigger !== undefined);

    return gemLackTriggers;
  }
);

export const stompSendAtom = atom(
  null,
  (
    get,
    set,
    message: string,
    signalingInfo: SignalingInfo | undefined = get(signalingInfoAtom)
  ) => {
    const stomp = get(stompAtom);
    if (!stomp || !signalingInfo) {
      return;
    }
    stomp.send({
      message,
      channelId: signalingInfo.channelId,
      clientId: signalingInfo.clientId,
    });
  }
);

const sendStompCameraStatusUpdatedAtom = atom(null, (get, set) => {
  if (get(statusAtom) !== STATUS.MATCHED) return;
  const isOnCamera = get(isOnCameraAtom);
  set(
    stompSendAtom,
    JSON.stringify({
      enabled: isOnCamera,
      matchId: get(matchInfoAtom)?.matchId,
      type: 'cameraStatusUpdated',
    })
  );
  const cameraInMatchInfo = get(cameraInMatchInfoAtom);
  const lastCameraChangedTimeInMatch = get(lastCameraChangedTimeInMatchAtom);
  const now = new Date().getTime();
  if (cameraInMatchInfo) {
    set(cameraInMatchInfoAtom, {
      ...cameraInMatchInfo,
      statusChangedTimeMs: [
        ...cameraInMatchInfo.statusChangedTimeMs,
        now - lastCameraChangedTimeInMatch,
      ],
    });
  } else {
    set(cameraInMatchInfoAtom, {
      activatedOnStart: isOnCamera,
      activatedOnEnd: isOnCamera,
      statusChangedTimeMs: [],
    });
  }
  set(lastCameraChangedTimeInMatchAtom, now);
});

const handleUpdatedItemsAtom = atom(null, async (get, set, updatedItems: InventoryItem[]) => {
  const inventoryItems = get(userInventoryAtom);
  if (!inventoryItems || !updatedItems) {
    return;
  }
  const nextUserInventory = [...inventoryItems];
  updatedItems.forEach((updatedItem) => {
    const target = inventoryItems.find(({ itemId }) => updatedItem.itemId === itemId);
    if (!target) {
      inventoryItems.push(updatedItem);
    } else {
      target.quantity = updatedItem.quantity;
    }
  });
  set(userInventoryAtom, nextUserInventory);
});
const reportMatchStartAtom = atom(null, async (get, set) => {
  const matchInfo = get(matchInfoAtom);
  const matchStat = get(matchStatAtom);

  if (matchInfo) {
    const matchUuid = createMatchUuid();
    set(matchUuidAtom, matchUuid);
    try {
      const response = await reportMatchStartAPI({
        matchId: matchInfo.matchId,
        iceConnectTimeMs: matchStat?.getIceConnectTime() || undefined,
        acceptTimeMs: -1,
      });
      if (response?.data) {
        const {
          data: {
            result: { updatedItems },
          },
        } = response;
        set(handleUpdatedItemsAtom, updatedItems);
      } else {
        Sentry.captureMessage(
          `Match Start's result field is null. matchId: ${matchInfo.matchId} json: ${response.data}`
        );
      }
    } catch (err) {
      Sentry.captureException(err);
    }
    set(sendStompCameraStatusUpdatedAtom);
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.MATCH_UUID,
      eventParams: { match_uuid: matchUuid, match_id: matchInfo.matchId },
    });
  }
});

const isMatchAcceptedAtom = atom(false);

const FALLBACK_SWIPE_DISABLE_DURATION_IN_MATCH = 5000;
const handleStartAtom = atom(null, (get, set) => {
  set(clearMatchWaitAtom);
  const audio = get(peerAudAtom);
  if (audio) {
    audio.muted = false;
  }

  set(eventMutateAtom, {
    eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
    eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
    eventParams: {
      debug: 'enter handleStartAtom',
      matchInfo: get(matchInfoAtom),
      isMatchAcceptedAtom,
    },
  });

  // ice connected랑 first frame, match accepted 순서 보장 안됨
  if (
    get(statusAtom) === STATUS.MATCHED ||
    !get(matchStatAtom)?.getIceConnectTime() ||
    !get(isMatchAcceptedAtom)
  )
    return;
  set(chatStartDateAtom, new Date());
  // video, audio 두번 일어날 수 있어서 한 번만 실행되게 처리

  set(eventMutateAtom, {
    eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
    eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
    eventParams: {
      debug: 'call set(statusAtom, STATUS.MATCHED)',
    },
  });
  set(statusAtom, STATUS.MATCHED);
  // 매치화면으로 넘어간 이후 과금되도록 나중에 실행
  set(reportMatchStartAtom);
  const userData = get(userDataAtom);
  const swipeDisableDuration = Math.floor(
    (get(matchInfoAtom)?.swipeDisabledDurationMs ??
      userData?.featureOptions.swipeDisabledDurationMs ??
      FALLBACK_SWIPE_DISABLE_DURATION_IN_MATCH) / 1000
  );
  // connecting에서 실행한 swipe disable를 clear
  set(clearSwipeDisabledAtom);
  set(swipeDisableDurationAtom, swipeDisableDuration);
  set(startDisableSecondAtom);
});

export const handleVirtualVideoErrorAtom = atom(null, (get, set, label: string) => {
  set(showModalAtom, {
    key: ModalType.ERROR,
    component: () => VirtualVideoErrorModal({ label }),
  });
});

export const receiveChatAtom = atom(null, (get, set, message: string) => {
  const matchInfo = get(matchInfoAtom);
  if (!matchInfo?.peerProfile) {
    return;
  }
  const nextChatList = get(chatListAtom).concat([
    {
      type: 'azar_message',
      message,
      sender: matchInfo.peerProfile,
    },
  ]);
  set(chatListAtom, nextChatList);
});

export const sendChatAtom = atom(null, async (get, set, message: string) => {
  const userData = get(userDataAtom);
  const matchInfo = get(matchInfoAtom);
  if (!userData?.userProfile || !matchInfo?.matchId || !message) {
    return;
  }
  const { matchId } = matchInfo;
  const { userProfile } = userData;
  await sendTextChatAPI({
    matchId,
    message,
  });
  set(chatListAtom, (prev) =>
    prev.concat([
      {
        type: 'azar_message',
        message,
        sender: userProfile,
      },
    ])
  );
});
export const startMatchAtom = atomWithPipes(
  null,
  async (get, set) => {
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: {
        debug: 'startMatchAtom',
      },
    });
    const stomp = get(stompAtom);
    if (!stomp) {
      Sentry.captureMessage('There is no stomp connection when startMatch');
      return;
    }
    const matchOptions = get(matchOptionsAtom);
    const matchFilterSelections = get(matchFilterSelectionsAtom);
    // onMeteredNetwork: PeerProfile.maxVideoBandwidthKbps 에 영향을 줍니다.
    // TODO(daniel.l): Browser 에서 사용 가능한 API 가 있는지 탐색
    const onMeteredNetwork = false;
    // deviceAvailableVideoHeight: 해당 기기에서 원활하게 인코딩/디코딩 할 수 있는 최대 세로 길이입니다.
    // TODO(daniel.l): Browser 에서 측정 가능한 방법이 있는지 확인
    const deviceAvailableVideoHeight = 720;
    const randomMatchContext: RandomMatchContext = {
      onMeteredNetwork,
      deviceAvailableVideoHeight,
      ca: get(isOnCameraAtom),
    };
    const matchEffectIds: string[] = [];

    const matchStat = new MatchStat();
    matchStat.beginProfileTime = Date.now();
    const userEffectSetting = get(userEffectSettingAtom);

    try {
      set(statusAtom, STATUS.FINDING);
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'matchRandomAPI start',
          request: {
            matchOptions,
            matchEffectIds,
            userEffectSetting,
            randomMatchContext,
            matchFilterSelections,
            previousMatchSummary: get(previousMatchSummaryAtom) ?? undefined,
          },
        },
      });
      const {
        data: { result },
      } = await matchRandomAPI({
        matchOptions,
        matchEffectIds,
        userEffectSetting,
        randomMatchContext,
        matchFilterSelections,
        previousMatchSummary: get(previousMatchSummaryAtom) ?? undefined,
      });
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'matchRandomAPI success',
          response: result,
        },
      });
      // 매치 요청 응답이 오기 전에 미러로 이동한 경우
      if (get(statusAtom) === STATUS.INITIAL) {
        set(eventMutateAtom, {
          eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
          eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
          eventParams: {
            debug: 'matchRandomAPI success but status is INITIAL',
          },
        });
        // 타이밍 이슈에 따라 매치가 생성될 수 있음
        // 생성된 매치의 상대 유저가 타임아웃까지 대기하는 것을 막기 위해 BYE만 전송
        set(stompSendAtom, 'BYE', result.signalingInfo);
        return;
      }
      const sourceStream = get(sourceStreamAtom);
      const isOnCamera = get(isOnCameraAtom);
      if (sourceStream) {
        const localAudioLevelGetter = new AudioLevelGetter({
          stream: sourceStream,
          callback: (audioLevel) => set(localAudioLevelAtom, getTargetAudioLevel(audioLevel)),
        });
        if (!isOnCamera) {
          localAudioLevelGetter.start();
        }
        set(localAudioLevelGetterAtom, localAudioLevelGetter);
      }

      if (window.innerWidth <= screenWidth.tablet) {
        set(headerAtom, false);
      }
      set(matchConfigAtom, result);
      set(matchStatAtom, matchStat);
      const { channelId, clientId } = result.signalingInfo;
      const headers = {
        selector: `sender-id <> '${clientId}'`,
        receipt: 'RECEIPT_ON_SUBSCRIBE',
      };

      stomp.subscribe({
        channelId,
        onMessage: (message) =>
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          set(handleMatchStompMessageAtom, {
            message,
            channelId,
          }),
        headers,
      });
      set(
        matchRetryTimerAtom,
        setTimeout(
          () => {
            set(matchTimeoutCountInARowAtom, (prev) => prev + 1);
            set(eventMutateAtom, {
              eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
              eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
              eventParams: {
                debug: 'matchRetryTimerAtom timeout',
                isOnline: window.navigator.onLine,
                lastStompMessage: get(lastStompMessageAtom),
                channelId,
                matchInfo: get(matchInfoAtom),
                matchTimeoutCountInARow: get(matchTimeoutCountInARowAtom),
              },
            });
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            set(handleDisconnectAtom, { reconnect: true, finisher: false });
          },
          (get(matchConfigAtom)?.matchTimeoutSeconds || 30) * 1000
        )
      );
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      set(clearMatchAtom);
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'startMatchAtom error',
          error,
        },
      });
      if (!isAxiosError<ErrorResponse<PunishedException>>(error)) return;
      const errorBody = error?.response?.data?.error?.data;
      if (!errorBody) {
        return;
      }
      switch (error?.response?.status) {
        case 403: {
          set(showModalAtom, {
            key: ModalType.MATCH_SUSPENSION,
            component: () =>
              SuspensionModal({
                punishment: errorBody,
              }),
          });
          break;
        }
        case 402: {
          set(closeModalAtom, ModalType.MATCH_SETTING);
          set(showModalAtom, {
            key: ModalType.GEM_LACK,
            component: () =>
              GemLackModal({
                lackingGemAmount: set(getMatchLackingGemAmountAtom),
                gemLackTriggers: set(getGemLackTriggersFromMatchFiltersAtom),
              }),
          });

          break;
        }
        case 400: {
          set(matchFilterSelectionsAtom, initialMatchFilterSelections);
          set(getMatchFiltersAtom);
          break;
        }
        default:
          Sentry.captureMessage('MatchRandomAPI unhandled error', {
            extra: { error },
          });
      }
    }
  },
  [exhaustMap]
);

export const myVideoAspectRatioAtom = atom(1);
export const handleStreamVideoAtom = atomWithPipes(
  null,
  async (get, set) => {
    const vidRef = get(vidRefAtom);
    const sourceStream = get(sourceStreamAtom);
    if (vidRef.current && sourceStream) {
      const video = document.createElement('video');
      const videoTracks = sourceStream.getVideoTracks();
      const { width = 0, height = 0 } = videoTracks[0].getSettings();
      set(myVideoAspectRatioAtom, width / height);
      const localVideoStream = new MediaStream(videoTracks);

      set(localVideoStreamAtom, localVideoStream);
      attachMediaElement(video, localVideoStream);

      const localVideoToView = new MediaStreamPipeManager();
      localVideoToView.setEl(video, vidRef.current);

      const effectStreamPipe = new EffectRendererPipe();
      localVideoToView.pipe(effectStreamPipe);

      localVideoToView.start();
      const prevLocalVideoToViewAtom = get(localVideoToViewAtom);
      if (prevLocalVideoToViewAtom) {
        prevLocalVideoToViewAtom.dispose();
      }
      set(localVideoToViewAtom, localVideoToView);

      set(closeModalAtom, ModalType.ERROR);
    }
  },
  [concatMap]
);

export const handleDecoStreamVideoAtom = atomWithPipes(
  null,
  (get, set) => {
    const vidRef = get(decoVidRefAtom);
    const sourceStream = get(sourceStreamAtom);
    if (!get(openedModalsAtom).find((modal) => modal.key === ModalType.EFFECTS)) {
      return;
    }
    if (vidRef.current && sourceStream) {
      const video = document.createElement('video');
      const videoTracks = sourceStream.getVideoTracks();
      const localVideoStream = new MediaStream(videoTracks);

      set(localVideoStreamAtom, localVideoStream);
      attachMediaElement(video, localVideoStream);

      const localVideoToView = new MediaStreamPipeManager();
      localVideoToView.setEl(video, vidRef.current, 'deco');

      const effectStreamPipe = new EffectRendererPipe();
      localVideoToView.pipe(effectStreamPipe);

      localVideoToView.start();

      const prevLocalVideoToViewAtom = get(localVideoToDecoViewAtom);
      if (prevLocalVideoToViewAtom) {
        prevLocalVideoToViewAtom.dispose();
      }
      set(localVideoToDecoViewAtom, localVideoToView);
    }
  },
  [concatMap]
);

const bannedVideoInputList = new RegExp(
  [
    'OBS',
    'XSplit',
    'Many',
    'Snap',
    'Chroma',
    'CamMask',
    'Split',
    'Alter',
    'Virtual',
    'Streamlabs',
    'VCam',
  ].join('|')
);

const validateVideoDeviceLabel = atom(null, (get, _set, stream: MediaStream) => {
  const videoDeviceLabel = stream.getVideoTracks()[0]?.label;
  const remoteConfig = get(remoteConfigAtom);
  if (!remoteConfig || !isObjectKeyTrue(remoteConfig, 'enableOBS')) {
    if (bannedVideoInputList.test(videoDeviceLabel)) {
      const err = new Error(videoDeviceLabel);
      err.name = 'VirtualCameraError';
      throw err;
    }
  }
});

type GrantVideoParams = {
  onSuccess?: () => void;
  errorModal?: React.FC;
};

const updateSourceStreamIfCamera = atom(null, async (get, set, onSuccess?: () => void) => {
  const sourceStream = get(sourceStreamAtom);
  if (get(isOnCameraAtom) && sourceStream && !sourceStream?.getVideoTracks()[0]?.enabled) {
    const audioTrack = sourceStream.getAudioTracks()[0];
    clearStream(sourceStream, { audio: false, video: true });
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      stream.addTrack(audioTrack);
      set(sourceStreamAtom, stream);
    } catch (_err) {
      const error = new Error();
      error.name = 'NotAllowedError';
      throw error;
    }
  }

  if (get(isOnCameraAtom)) {
    await set(handleStreamVideoAtom);
  }

  onSuccess?.();
});

const createSourceStream = atom(null, async (get, set, onSuccess?: () => void) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    set(validateVideoDeviceLabel, stream);
    set(sourceStreamAtom, stream);
  } catch (_err) {
    const error = new Error();
    error.name = 'NotAllowedError';
    throw error;
  }

  if (get(isOnCameraAtom)) {
    await set(handleStreamVideoAtom);
  }

  onSuccess?.();
});
const handleSourceStream = atom(null, async (get, set, onSuccess?: () => void) => {
  const sourceStream = get(sourceStreamAtom);
  if (sourceStream) {
    await set(updateSourceStreamIfCamera, onSuccess);
  } else {
    await set(createSourceStream, onSuccess);
  }
});

export const grantVideoAtom = atom(null, async (_get, set, params?: GrantVideoParams) => {
  if (!navigator?.mediaDevices?.getUserMedia) {
    Sentry.captureMessage('getUserMedia를 지원하지 않는 브라우저입니다.');
    set(showToastAtom, {
      type: ToastType.ERROR,
      message: i18n?.t('POPUP_NOT_SUPPORTED_DESC') || '',
    });
    return;
  }
  try {
    await set(handleSourceStream, params?.onSuccess);
  } catch (err) {
    if ((err as Error).name === 'VirtualCameraError') {
      set(handleVirtualVideoErrorAtom, (err as Error).message);
    } else if ((err as Error).name === 'NotAllowedError' && params?.errorModal) {
      set(showModalAtom, {
        key: ModalType.ERROR,
        component: params.errorModal,
      });
    }
  }
});

export const clearSourceStreamAtom = atom(null, (get, set) => {
  const sourceStream = get(sourceStreamAtom);
  if (sourceStream) {
    const localVideoToDecoView = get(localVideoToDecoViewAtom);
    if (localVideoToDecoView) {
      localVideoToDecoView.dispose(true);
      set(localVideoToDecoViewAtom, null);
    }

    clearStream(sourceStream);
    set(sourceStreamAtom, null);
    const localVideoToView = get(localVideoToViewAtom);
    localVideoToView?.dispose(true);
    set(localVideoToViewAtom, null);
    set(localVideoStreamAtom, null);
  }
});

export const setStepAtom = atom(null, (get, set, nextStep: number) => {
  if (window.innerWidth <= screenWidth.tablet) {
    set(headerAtom, nextStep === 1);
  }
  if (nextStep === 2) {
    set(statusAtom, STATUS.FINDING);
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.SCREEN_OPENED__MATCH_FINDING,
    });
    set(startMatchAtom);
  }
  set(stepAtom, nextStep);
});

const reportMLInferencesAtom = atom(null, (get, set) => {
  const matchInfo = get(matchInfoAtom);
  if (!matchInfo || !matchInfo.matchId) return;

  const inferences = get(antmanInferenceQueueAtom);
  if (inferences.length === 0) return;

  const reportIndex = get(antmanInferenceReportIndexAtom);

  set(antmanInferenceQueueAtom, RESET);
  set(antmanInferenceReportIndexAtom, (prev) => prev + 1);

  // Inference logging은 작업 실패에 대한 처리 없음
  reportMLMatchInferencesAPI({
    matchId: matchInfo.matchId,
    models: [
      {
        modelId: get(antmanConfigAtom).modelId,
        index: reportIndex,
        inferences,
      },
    ],
  });
});

const clearMLInferenceIntervalAtom = atom(null, (get, set) => {
  const timerId = get(mlInferenceReportTimerAtom);

  if (timerId !== null) {
    clearInterval(timerId);
    set(mlInferenceReportTimerAtom, null);
  }
});
// report modal open 시에 video, audio 차단
export const isBlockedPeerVideoAtom = atom(false);
export const blockPeerMediaAtom = atom(null, (get, set, isBlock: boolean) => {
  const peerAud = get(peerAudAtom);
  if (peerAud) {
    peerAud.muted = isBlock;
  }
  set(isBlockedPeerVideoAtom, isBlock);
});
export const closeMatchReportModalAtom = atom(null, (get, set) => {
  set(closeModalAtom, ModalType.REPORT);
  set(blockPeerMediaAtom, false);
});

const handleAhaMomentEventAtom = atom(null, (get, set) => {
  const userJoinDate = get(userJoinDateAtom);
  const chatStartDate = get(chatStartDateAtom);
  const matchDuration = chatStartDate ? differenceInSeconds(new Date(), chatStartDate) : 0;
  const prevTotalMatchDuration = get(totalMatchTimeSecAtom);
  const nowTotalMatchDuration = prevTotalMatchDuration + matchDuration;
  set(totalMatchTimeSecAtom, nowTotalMatchDuration);
  if (!userJoinDate) return;
  const userJoinAfterOneWeek = addDays(userJoinDate, 7);
  if (isPast(userJoinAfterOneWeek)) return;
  if (nowTotalMatchDuration < 600) {
    return;
  }
  if (prevTotalMatchDuration < 600 && 600 <= nowTotalMatchDuration) {
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.AHAMOMENT_MATCH_10MIN,
      eventParams: { duration: matchDuration },
    });
    return;
  }
  if (prevTotalMatchDuration < 1200 && 1200 <= nowTotalMatchDuration) {
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.AHAMOMENT_MATCH_20MIN,
      eventParams: { duration: matchDuration },
    });
    return;
  }
  if (prevTotalMatchDuration < 1800 && 1800 <= nowTotalMatchDuration) {
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.AHAMOMENT_MATCH_30MIN,
      eventParams: { duration: matchDuration },
    });
    return;
  }
});

const reportMLAtom = atom(null, (get, set, reconnect: boolean) => {
  const matchInfo = get(matchInfoAtom);
  const antmanCaptured = get(antmanCapturedAtom);
  const matchId = matchInfo?.matchId;

  if (matchId && (get(antmanEnableAtom) || get(antmanSuspendedAtom))) {
    // 큐에 대기중이던 inference들이 남아있는 경우 마저 전송
    set(reportMLInferencesAtom);

    const { modelId, blurConfig } = get(antmanConfigAtom);

    reportMLMatchSummaryAPI({
      matchId: matchInfo.matchId,
      matchSummaryModelReports: [
        {
          modelId,
          inferencesCount: get(antmanInferenceCountAtom),
          averageLatencyMs:
            get(antmanInferenceCountAtom) > 0
              ? Math.round(get(antmanLatencySumAtom) / get(antmanInferenceCountAtom))
              : 0,
          usedGPU: false,
          usedXNNPACK: true,
          suspendPeriodicReport: get(antmanSuspendedAtom),
        },
      ],
      matchSummaryFeatureReports: [
        {
          type: 'MatchBlur',
          machineLearningThresholdInference: antmanCaptured
            ? [
                {
                  model: MLModelType.Antman,
                  taskIndex: 1,
                  index: antmanCaptured.inferenceIndex,
                },
              ]
            : [],
          machineLearningFeatureTriggerInferences: antmanCaptured
            ? [
                {
                  model: MLModelType.Antman,
                  taskIndex: 1,
                  index: antmanCaptured.inferenceIndex,
                  criteriaWindow: blurConfig.criteriaWindow,
                  triggeredCount: 1,
                },
              ]
            : [],
        },
      ],
    });
  }

  set(antmanSuspendedAtom, RESET);
  set(antmanInferenceCountAtom, RESET);
  set(antmanInferenceQueueAtom, RESET);
  set(antmanInferenceReportIndexAtom, RESET);
  set(antmanLatencySumAtom, RESET);

  if (antmanCaptured && !antmanCaptured.handled) {
    set(
      MLMetricsAtom,
      get(MLMetricsAtom).concat([
        {
          triggeredAt: antmanCaptured.triggeredAt,
          action: !reconnect ? 'leaved' : 'blurred',
          feature: 'MatchBlur',
          modelType: MLModelType.Antman,
          actedAt: new Date().getTime(),
        },
      ])
    );
  }
});

const reportMatchResultAtom = atom(
  null,
  async (get, set, { reconnect, finisher }: { reconnect: boolean; finisher: boolean }) => {
    if (get(statusAtom) !== STATUS.MATCHED) return;
    const matchInfo = get(matchInfoAtom);
    const matchId = matchInfo?.matchId;
    if (!matchId) return;

    const chatStartDate = get(chatStartDateAtom) || undefined;
    const matchStat = get(matchStatAtom);
    const videoCallStat = get(videoCallStatAtom);
    const peerConnection = get(peerConnectionAtom);
    const webrtcMatchStats = {
      ...(peerConnection?.getWebRTCMatchStats() || {}),
      peerProfileTimeMs: matchStat?.getPeerProfileTimeMs(),
    };
    const localCandidateType = videoCallStat?.localCandidateType || undefined;
    const remoteCandidateType = videoCallStat?.remoteCandidateType || undefined;
    const iceConnectTimeMs = matchStat?.getIceConnectTime() || undefined;
    const cameraInMatchInfo = get(cameraInMatchInfoAtom);
    const isOnCamera = get(isOnCameraAtom);
    // NOTE(daniel.l): reportMatchMetrics 의 iceConnectTimeMs 는 deprecate 되었다는데,
    // 확인 후 추후 제거
    const chatDurationMs = chatStartDate && new Date().getTime() - chatStartDate.getTime();
    const antmanCaptured = get(antmanCapturedAtom);
    const eventObj = {
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: reconnect ? EVENT_NAME.MATCHING__NEXT : EVENT_NAME.MATCHING__EXIT,
      eventParams: {
        reconnect,
        finisher,
        ...(!!antmanCaptured && { screen: 'blur' }),
      },
    };

    const matchConfig = get(matchConfigAtom);
    if (matchConfig?.shouldReportMatchSummary) {
      set(previousMatchSummaryAtom, {
        matchId,
        chatDurationMs: chatDurationMs ?? 0,
        finisher,
        bonusMatch: !!matchConfig?.bonusMatchStatus?.bonusMatch,
      });
    } else {
      set(previousMatchSummaryAtom, null);
    }

    Promise.all([
      reportMatchMetricsAPI({
        matchUid: matchId,
        initiator: matchInfo?.initiator,
        chatDurationMs,
        finisher,
        firstFrameReceivedTimeMs: matchStat?.getFirstFrameReceivedTimeMs() || undefined,
        localCandidateType,
        remoteCandidateType,
        iceConnectTimeMs,
        ...(cameraInMatchInfo && {
          cameraInMatchInfo: {
            ...cameraInMatchInfo,
            activatedOnEnd: isOnCamera,
          },
        }),
        /*
      facialBlurOnWhenLogin: undefined,
      facePositions: undefined,
      numRecognizedFace: undefined,
      */
      }),
      sendWebRTCStatInfoV1API({
        matchId,
        stats: webrtcMatchStats,
      }),
      reportMLMatchMetricsAPI({
        matchId,
        actions: get(MLMetricsAtom),
      }),
      set(eventMutateAtom, eventObj),
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      set(pushMatchViewModeEvent, { matchId, chatDurationMs }),
    ]).catch((e) => {
      // metric 집계 에러
      Sentry.captureException(e);
    });
  }
);

const reportContentsMatchResultAtom = atom(null, (get) => {
  if (get(statusAtom) !== STATUS.CONTENTS_MATCH) return;
  const contentsMatchConfig = get(contentsMatchConfigAtom);
  if (!contentsMatchConfig) return;

  const chatStartDate = get(chatStartDateAtom);
  const contentsMatchPlayTimeMs = chatStartDate
    ? new Date().getTime() - chatStartDate.getTime()
    : 0;
  reportContentsMatchMetricsAPI({
    contentsMatchId: contentsMatchConfig.contentsMatchId,
    contentsMatchPlayTimeMs,
  });
});

export const reportBeforeUnloadAtom = atom(null, (_get, set) => {
  set(reportMLAtom, false);
  set(reportMatchResultAtom, {
    reconnect: false,
    finisher: true,
  });
  set(reportContentsMatchResultAtom);
});

export const handleDisconnectAtom = atomWithPipes(
  null,
  async (get, set, { reconnect, finisher }: { reconnect: boolean; finisher: boolean }) => {
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: {
        debug: 'handleDisconnectAtom',
        reconnect,
        finisher,
      },
    });

    const status = get(statusAtom);
    const stomp = get(stompAtom);
    set(handleAhaMomentEventAtom);
    set(matchUuidAtom, '');
    if (status === STATUS.INITIAL) {
      return;
    }

    const matchInfo = get(matchInfoAtom);
    const signalingInfo = get(signalingInfoAtom);
    if (stomp && signalingInfo) {
      stomp.unsubscribe(signalingInfo.channelId);
    }
    if (finisher) {
      set(stompSendAtom, 'BYE');
    }

    set(clearMLInferenceIntervalAtom);

    const matchId = matchInfo?.matchId;
    const contentsMatchConfig = get(contentsMatchConfigAtom);

    set(reportMLAtom, reconnect);

    // 컨텐츠 매치 시작한 상태
    if (contentsMatchConfig && status === STATUS.CONTENTS_MATCH) {
      set(reportContentsMatchResultAtom);
    } else if (!matchId && signalingInfo) {
      await cancelMatchRandomAPI();
    } else if (finisher && matchId && status === STATUS.CONNECTING) {
      const {
        data: {
          result: { updatedItems },
        },
      } = await abortMatchAPI({ matchId });
      set(handleUpdatedItemsAtom, updatedItems);
    } else if (matchId && status === STATUS.MATCHED) {
      set(reportMatchResultAtom, { reconnect, finisher });
    }

    if (!reconnect) {
      set(setStepAtom, 1);
      set(statusAtom, STATUS.INITIAL);
      get(localAudioLevelGetterAtom)?.stop();
      set(localAudioLevelGetterAtom, undefined);
    }

    set(MLMetricsAtom, []);
    set(clearDisplayDelayAtom);
    set(clearMatchWaitAtom);
    set(clearMatchRetryAtom);
    set(clearSwipeDisabledAtom);
    get(peerAudioLevelGetterAtom)?.stop();
    set(peerAudioLevelGetterAtom, undefined);
    set(peerAudioLevelAtom, 0);
    set(chatListAtom, []);
    set(matchInfoAtom, undefined);
    set(matchConfigAtom, undefined);
    set(contentsMatchConfigAtom, null);
    set(matchStatAtom, undefined);
    set(videoCallStatAtom, RESET);
    set(isMatchAcceptedAtom, false);
    const peerAud = get(peerAudAtom);
    if (peerAud) {
      detachMediaElement(peerAud);
      set(peerAudAtom, null);
    }
    const peerVideo = get(peerVideoAtom);
    set(peerCameraEnabledAtom, true);
    if (peerVideo) {
      peerVideo.dispose(true);
      set(peerVideoAtom, null);
    }
    set(chatStartDateAtom, null);
    set(closeMatchReportModalAtom);
    const localVideoToSend = get(localVideoToSendAtom);
    if (localVideoToSend) {
      localVideoToSend.dispose();
      set(localVideoToSendAtom, null);
    }
    set(antmanCapturedAtom, null);
    set(antmanResultAtom, RESET);
    set(cameraInMatchInfoAtom, null);
    set(lastCameraChangedTimeInMatchAtom, 0);
    const peerConnection = get(peerConnectionAtom);
    if (peerConnection) {
      peerConnection.close();
      set(peerConnectionAtom, null);
    }

    if (reconnect) {
      set(statusAtom, STATUS.FINDING);
      await set(startMatchAtom);
    }
  },

  [concatMap]
);

const reportMatchStatusAtom = atom(false);
export const reportMatchAtom = atom(
  null,
  async (
    get,
    set,
    {
      reportType,
      reportMethod,
      file,
    }: Pick<ReportMatchParams, 'reportType' | 'reportMethod'> & {
      file: Blob | null;
    }
  ) => {
    if (get(reportMatchStatusAtom)) {
      return;
    }
    set(reportMatchStatusAtom, true);
    const matchId = get(matchInfoAtom)?.matchId;
    if (!matchId) return;

    try {
      let fileInfo = null;

      if (file) {
        const isCaptureImageEmpty = await isImageFileEmpty(file).catch((err) => {
          Sentry.captureException(err);
        });
        if (isCaptureImageEmpty) {
          let remoteInboundRtpStreamStats = {};

          const peerConnection = get(peerConnectionAtom);
          const stats = await peerConnection?.getStats();
          if (stats) {
            stats.forEach((stat) => {
              if (stat.type === 'remote-inbound-rtp') {
                remoteInboundRtpStreamStats = stat;
              }
            });
          }
          Sentry.captureMessage(`Report image captured but empty`, {
            extra: {
              remoteInboundRtpStreamStats,
            },
          });
        } else if (isCaptureImageEmpty === false) {
          // 혹시나 이미지 파일 업로드 실패하더라도 신고 API 호출하도록 catch
          fileInfo = await uploadContentManager(file).catch(() => null);
        }
      }

      await ReportMatchAPI({
        matchId,
        reportType,
        fileInfo,
        reportMethod,
      });
      set(showToastAtom, {
        message: 'REPORT_COMPLETED',
        type: ToastType.SUCCESS,
      });
      await set(handleDisconnectAtom, { reconnect: true, finisher: true });
    } catch (e) {
      set(showToastAtom, {
        message: 'REPORT_FAILED',
        type: ToastType.ERROR,
      });
    } finally {
      set(closeMatchReportModalAtom);
      set(reportMatchStatusAtom, false);
    }
  }
);

export const closeAntmanWarningAtom = atom(null, async (get, set, shouldReport) => {
  const antmanCaptured = get(antmanCapturedAtom);
  if (!antmanCaptured) {
    return;
  }
  set(eventMutateAtom, {
    eventType: EVENT_TYPE.VIDEO_CHAT,
    eventName: shouldReport ? EVENT_NAME.BLUR__REPORT_AND_LEAVE : EVENT_NAME.BLUR__CONTINUE_MATCH,
  });
  const { file, triggeredAt } = antmanCaptured;
  set(
    MLMetricsAtom,
    get(MLMetricsAtom).concat([
      {
        triggeredAt,
        action: shouldReport ? 'reported' : 'dismissed',
        feature: 'MatchBlur',
        modelType: MLModelType.Antman,
        actedAt: new Date().getTime(),
      },
    ])
  );
  if (shouldReport) {
    await set(reportMatchAtom, {
      reportType: ReportType.VISUAL_ABUSE,
      reportMethod: ReportMatchMethod.IN_MATCH_ON_BLUR,
      file,
    });
  }
  set(antmanCapturedAtom, { ...antmanCaptured, handled: true });
});

export const clearMatchAtom = atomWithPipes(
  null,
  async (get, set) => {
    await set(handleDisconnectAtom, { reconnect: false, finisher: true });
    /**
     * PG 결제의 특성 상 결제 완료 -> 승인 및 재화 지급은 비동기적으로 동작
     * 관련 타이밍 이슈로 매치 중 구매 시 매치 시작 API에서 획득한 인벤토리 정보와 구매 상품 지급으로 인한 브로커 메시지의 인벤토리가 충돌할 수 있음
     * 미러 등에서의 최소한 정합성 보장을 위해 매치 플로우를 이탈할 때 명시적으로 인벤토리를 최신화
     */
    await set(getInventoryAtom);
    SilentAudioPlayer.Instance.stop();
  },
  [exhaustMap]
);

export const attachTracksAtom = atom(null, (get, set) => {
  const vidRef = get(vidRefAtom);
  if (!vidRef.current) {
    return;
  }
  const peerConnection = get(peerConnectionAtom);
  const sourceStream = get(sourceStreamAtom);
  const localVideoToView = get(localVideoToViewAtom);

  const video = document.createElement('video');
  if (!localVideoToView?.mediaStream || !sourceStream) {
    return;
  }
  attachMediaElement(video, localVideoToView.mediaStream);
  const localVideoToSend = new MediaStreamPipeManager();
  localVideoToSend.setEl(video);
  localVideoToSend.pipe(new RotateMediaStream(90, true));
  const { mediaStream: transformVideoStream, width, height } = localVideoToSend.start();
  set(localVideoToSendAtom, localVideoToSend);
  if (width && height) {
    peerConnection?.setInputVideoSize(width, height);
  }
  const audioTracks = sourceStream.getAudioTracks() || [];
  const videoTracks = transformVideoStream.getVideoTracks() || [];
  [...audioTracks, ...videoTracks].forEach((track) => {
    peerConnection?.addTrack(track);
  });
});

const handleDescriptionAtom = atom(
  null,
  (get, set, description: RTCSessionDescriptionInit | null) => {
    if (!description) {
      return;
    }
    set(stompSendAtom, JSON.stringify(description));
  }
);

const handleIceCandidateAtom = atom(null, (_get, set, e: RTCPeerConnectionIceEvent) => {
  if (!e.candidate) {
    return;
  }

  const candidateStr = e.candidate.candidate;
  if (candidateStr === '') {
    // TODO(daniel.l): Firefox 에서만 생성되는 것 확인되면 제거
    Sentry.captureMessage('end-of-candidates created');
    return;
  } else {
    if (!isValidCandidate(candidateStr)) {
      Sentry.captureMessage('Invalid candidate created', {
        extra: {
          sdpMid: e.candidate.sdpMid,
          sdpMLineIndex: e.candidate.sdpMLineIndex,
          candidate: candidateStr,
        },
      });
      return;
    }
  }

  const candidate = {
    id: e.candidate.sdpMid,
    type: 'candidate',
    candidate: candidateStr,
    label: e.candidate.sdpMLineIndex,
  };
  set(stompSendAtom, JSON.stringify(candidate));
});

const handleTrackAtom = atom(null, (get, set, e: RTCTrackEvent) => {
  const matchInfo = get(matchInfoAtom);
  if (!matchInfo) {
    return;
  }
  const { hasChoice } = matchInfo.peerProfile;
  let el: HTMLMediaElement | null = null;

  set(eventMutateAtom, {
    eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
    eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
    eventParams: {
      debug: 'handleTrackAtom',
      trackKind: e.track.kind,
    },
  });

  if (e.track.kind === 'audio') {
    const peerAud = get(peerAudAtom);
    el = peerAud || document.createElement('audio');
    if (!peerAud) {
      set(peerAudAtom, el);
    }
    const stream = new MediaStream([e.track]);
    attachMediaElement(el, stream);
    el.muted = true;
    try {
      const peerAudioLevelGetter = new AudioLevelGetter({
        stream,
        callback: (audioLevel) => set(peerAudioLevelAtom, getTargetAudioLevel(audioLevel)),
      });
      if (get(peerCameraEnabledAtom)) {
        peerAudioLevelGetter.start();
      }
      set(peerAudioLevelGetterAtom, peerAudioLevelGetter);
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'handleTrackAtom audio success',
        },
      });
    } catch (_e) {
      console.error('AudioLevelGetter start failed');
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'handleTrackAtom audio failed',
          error: _e,
        },
      });
    }
  } else {
    const peerVidRef = get(peerVidRefAtom);
    const video = peerVidRef.current;
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: {
        debug: 'handleTrackAtom video',
        hasPeerVideo: !!video,
      },
    });

    if (!video) {
      return;
    }
    const tmpVideo = document.createElement('video');
    el = tmpVideo;

    tmpVideo.addEventListener('loadeddata', async () => {
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'handleTrackAtom video loadeddata',
        },
      });
      const peerVideo = new MediaStreamPipeManager();
      peerVideo.setEl(tmpVideo, video);
      peerVideo.pipe(new RotateMediaStream(270, false));
      if (get(antmanEnableAtom)) {
        const antmanMediaStream = await set(startAntmanAtom, {
          onResult: (antmanResult) => {
            // 관련된 매치가 종료된 이후에 추론 결과가 나온 경우
            if (matchInfo.matchId !== get(matchInfoAtom)?.matchId) {
              antmanMediaStream.dispose();
              return;
            }

            const { result, time } = antmanResult;
            set(antmanResultAtom, antmanResult);

            const inference = result.map((v) => v.toExponential(4));

            set(antmanInferenceQueueAtom, (prev) => [
              ...prev,
              {
                // antman 모델은 internal state 존재하지 않기 때문에 값 고정
                modelStateInitialized: false,
                inferenceAt: new Date().getTime(),
                latencyMs: Math.round(time),
                inference,
              },
            ]);

            set(antmanInferenceCountAtom, (prev) => prev + 1);
            set(antmanLatencySumAtom, (prev) => prev + time);
          },
          onTrigger: () => {
            const image = video.toDataURL('image/png');
            if (image) {
              const file = dataURItoBlob(image);
              set(antmanCapturedAtom, {
                file,
                triggeredAt: new Date().getTime(),
                inferenceIndex: get(antmanInferenceCountAtom),
                handled: false,
              });
              peerVideo.unpipe(antmanMediaStream);
              set(eventMutateAtom, {
                eventType: EVENT_TYPE.VIDEO_CHAT,
                eventName: EVENT_NAME.MATCHING_ACTIVATE_BLUR,
              });
            }
          },
          onSuspend: () => {
            peerVideo.unpipe(antmanMediaStream);
          },
        });

        peerVideo.pipe(antmanMediaStream);
        const timerId = setInterval(() => {
          set(reportMLInferencesAtom);
        }, ML_INFERENCE_REPORT_INTERVAL_MS);
        set(mlInferenceReportTimerAtom, timerId);
      }
      peerVideo.start();
      set(peerVideoAtom, peerVideo);
    });
    attachMediaElement(tmpVideo, new MediaStream([e.track]), () => {
      get(matchStatAtom)?.gotFirstFrame();
    }).catch((err) => {
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'handleTrackAtom attachMediaElement failed',
          error: err,
        },
      });
    });
    const fdMsg = {
      type: 'faceDetection',
      faceDetected: 'FACE_DETECTED',
    };
    set(stompSendAtom, JSON.stringify(fdMsg));
  }
  el.addEventListener('loadeddata', () => {
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: {
        debug: 'el.addEventListener("loadeddata", () => {',
        matchInfo: get(matchInfoAtom),
        hasChoice,
      },
    });
    if (!get(matchInfoAtom)) {
      el = null;
      return;
    }
    if (!hasChoice) {
      set(isMatchAcceptedAtom, true);
      // audio, video 순서 보장 안됨, 올지 안올지 모름
      set(handleStartAtom);
    }
  });
});

const handlePeerConnectionAtom = atom(null, async (get, set, matchInfo: MatchInfoMessage) => {
  set(clearMatchRetryAtom);
  const connection = new PeerConnectionClient({
    onTrackEventHandler: (...args) => set(handleTrackAtom, ...args),
    onICECandidateHandler: (...args) => set(handleIceCandidateAtom, ...args),
    onVideoCallStats: (stats) => {
      set(videoCallStatAtom, stats);
    },
    onIceConnectionStateChange: () => {
      const iceConnectionState = connection.getIceConnectionState();
      if (!iceConnectionState) {
        return;
      }
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'onIceConnectionStateChange',
          iceConnectionState,
        },
      });
      switch (iceConnectionState) {
        case 'connected': {
          const matchStat = get(matchStatAtom);
          const iceConnectTime = matchStat?.getIceConnectTime();
          if (matchStat && !iceConnectTime) {
            matchStat.setEndIceConnectTime(Date.now());
            set(handleStartAtom);
          }
          break;
        }
        default:
          break;
      }
    },
    onConnectionStateChange: () => {
      const connectionState = connection.getConnectionState();
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: {
          debug: 'onConnectionStateChange',
          connectionState,
        },
      });
      if (!connectionState) {
        return;
      }
      switch (connectionState) {
        case 'disconnected':
        case 'failed':
        case 'closed': {
          set(handleDisconnectAtom, { reconnect: true, finisher: false });
          break;
        }
        default:
          break;
      }
    },
    onIceCandidateError: async (event: RTCPeerConnectionIceErrorEvent) => {
      if (event.errorCode >= 400 && event.errorCode < 500) {
        const iceServerManager = IceServerManager.getInstance();
        iceServerManager.reportConnectionFail({
          errorMessage: event.errorText,
          generalConnectionFailType: 'TURN',
          generalConnectionAddress: [event.url],
        });
        await iceServerManager.fetchIceServers();
      }
    },
  });
  set(eventMutateAtom, {
    eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
    eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
    eventParams: {
      debug: 'set peerConnectionAtom',
    },
  });
  set(peerConnectionAtom, connection);
  const iceServers = await IceServerManager.getInstance().getIceServers();
  const rtcIceServers: RTCIceServer[] = iceServers.map(({ uri, username, password }) => ({
    urls: uri,
    username,
    credential: password,
  }));
  connection.open({ iceServers: rtcIceServers });
  set(attachTracksAtom);

  try {
    await connection.setMatchVideoHeight(matchInfo.matchVideoHeight);
    await connection.setMaxVideoBandwidth(matchInfo.peerProfile.maxVideoBandwidthKbps);
    const remoteConfig = get(remoteConfigAtom);
    connection.setVideoStartBitrateMode(
      remoteConfig ? isObjectKeyTrue(remoteConfig, 'useStartBitrateControl') : false
    );
    if (remoteConfig?.startBitrateControlAlphaPercent) {
      connection.setStartBitrateControlAlpha(remoteConfig.startBitrateControlAlphaPercent / 100);
    }
    if (matchInfo.initiator) {
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
        eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
        eventParams: { debug: 'makeOffer' },
      });
      await connection.makeOffer().then((description) => set(handleDescriptionAtom, description));
    }
  } catch (err) {
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
      eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
      eventParams: { debug: 'handlePeerConnectionAtom failed', error: err },
    });
    Sentry.captureException(err);
  }
  set(matchInfoAtom, matchInfo);
  set(clearDisplayDelayAtom);
  set(matchWaitAtom);
});

export const handleChatMessage = atom(null, (get, set, chatMessage: ChatMsgMessage) => {
  set(receiveChatAtom, chatMessage.originalMessage);
});

export const handleContentsMatchStartAtom = atom(null, (get, set) => {
  set(chatStartDateAtom, new Date());
  set(statusAtom, STATUS.CONTENTS_MATCH);

  const contentsMatchId = get(contentsMatchConfigAtom)?.contentsMatchId;

  if (contentsMatchId) {
    reportContentsMatchStartAPI({
      contentsMatchId,
    });
  }
});

const loadContentsMatchAtom = atom(null, (get, set, contentsMatchConfig: ContentsMatchMessage) => {
  set(clearMatchRetryAtom);
  set(contentsMatchConfigAtom, contentsMatchConfig);
  set(statusAtom, STATUS.CONNECTING);

  switch (contentsMatchConfig.contentsMatchMediaInfo.mediaType) {
    case 'STARTER_PACKAGE_NUDGE':
      set(showModalAtom, {
        key: ModalType.STARTER_PACKAGE_NUDGING,
        component: MatchStarterPackageNudgingModal,
      });
      break;
    default:
      // invalid contents match
      set(handleDisconnectAtom, {
        reconnect: true,
        finisher: true,
      });
      break;
  }
});

export const handleMatchStompMessageAtom = atom(
  null,
  async (get, set, { message, channelId }: { message: IMessage; channelId: string }) => {
    if (!message.body) {
      return;
    }
    const signalingInfo = get(signalingInfoAtom);
    if (!signalingInfo || signalingInfo.channelId !== channelId) {
      return;
    }

    set(eventMutateAtom, {
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.MATCH_RECEIVE__STOMP_MESSAGE,
      eventParams: { body: message.body },
    });
    if (message.body === 'BYE') {
      set(handleDisconnectAtom, {
        reconnect: get(statusAtom) >= STATUS.FINDING,
        finisher: false,
      });
      return;
    }

    const peerConnection = get(peerConnectionAtom);

    const brokerMessage = JSON.parse(message.body) as MatchBrokerMessage;
    switch (brokerMessage.type) {
      case 'contentsMatchMessage': {
        set(loadContentsMatchAtom, brokerMessage);
        break;
      }
      case 'cameraStatusUpdated': {
        set(peerCameraEnabledAtom, brokerMessage.enabled);
        const peerAudioLevelGetter = get(peerAudioLevelGetterAtom);
        if (peerAudioLevelGetter) {
          if (brokerMessage.enabled) {
            peerAudioLevelGetter.pause();
          } else {
            peerAudioLevelGetter.start();
          }
        }
        break;
      }
      case 'matchToLiveRoom':
        set(handleDisconnectAtom, { reconnect: true, finisher: false });
        return;
      case 'matchInfo':
        set(eventMutateAtom, {
          eventType: EVENT_TYPE.VIDEO_CHAT_DEBUG,
          eventName: EVENT_NAME.DEBUG__MATCH_LOGGING,
          eventParams: {
            debug: 'receive matchInfo',
            matchInfo: brokerMessage,
            hasPeerConnection: !!peerConnection,
          },
        });
        if (!peerConnection) {
          const matchStat = get(matchStatAtom);
          if (matchStat != null) {
            const now = Date.now();
            matchStat.endProfileTime = now;
            matchStat.beginIceConnectTime = now;
          }
          set(handlePeerConnectionAtom, brokerMessage);
        } else {
          Sentry.captureMessage('received matchInfo while connection exist', {
            extra: {
              matchInfo: brokerMessage,
              hasPeerConnection: !!peerConnection,
              connectionState: peerConnection.getConnectionState(),
              signalingState: peerConnection.getSignalingState(),
            },
          });
        }
        break;
      case 'offer': {
        try {
          await peerConnection?.setRemoteDescription(brokerMessage);
          await peerConnection
            ?.makeAnswer()
            .then((description) => set(handleDescriptionAtom, description));
        } catch (_e) {
          Sentry.captureException(_e);
          set(handleDisconnectAtom, { reconnect: true, finisher: false });
          return;
        }
        break;
      }
      case 'answer':
        try {
          await peerConnection?.setRemoteDescription(brokerMessage);
        } catch (_e) {
          Sentry.captureException(_e);
          set(handleDisconnectAtom, { reconnect: true, finisher: false });
          return;
        }
        break;
      case 'candidate': {
        try {
          await peerConnection?.addIceCandidate(brokerMessage);
        } catch (_e) {
          Sentry.captureException(_e, { data: brokerMessage });
          set(handleDisconnectAtom, { reconnect: true, finisher: false });
          return;
        }
        break;
      }
      // TODO: 이거 받아야 함
      case 'matchAccepted': {
        // ios easy connect 꺼져있을 때, 즉 hasChoice == true 일 때 matchAccepted 받아야 status -> matched로 넘어감
        set(isMatchAcceptedAtom, true);
        set(handleStartAtom);
        break;
      }
      case 'systemMessageInMatch': {
        set(showToastAtom, {
          message: brokerMessage.message,
          type: ToastType.ERROR,
        });
        break;
      }
      case 'chatMsg': {
        set(handleChatMessage, brokerMessage);
        break;
      }
      case 'pranswer':
      case 'rollback':
        break;
    }
  }
);

const mobileLayoutStorageAtom = getAtomWithStorage<'DEFAULT' | 'HALF' | null>('mobileLayout', null);

export const mobileLayoutAtom = atom(
  (get) => {
    const storageValue = get(mobileLayoutStorageAtom);
    if (storageValue !== null) return storageValue;

    const remoteConfig = get(remoteConfigAtom);
    if (remoteConfig) {
      return isObjectKeyTrue(remoteConfig, 'splitViewUi') ? 'HALF' : 'DEFAULT';
    }
    return 'DEFAULT';
  },
  (get, set, newValue: 'DEFAULT' | 'HALF' | null) => {
    set(mobileLayoutStorageAtom, newValue);
  }
);

export const toggleVideoTracksAtom = atom(null, async (get, set) => {
  const localVideoStream = get(localVideoStreamAtom);
  const videoTracks = localVideoStream?.getVideoTracks();
  const localAudioLevelGetter = get(localAudioLevelGetterAtom);
  if (videoTracks && videoTracks.length > 0) {
    const videoTrack = videoTracks[0];
    videoTrack.enabled = false;
    videoTrack.stop();
    localVideoStream?.removeTrack(videoTrack);
    localAudioLevelGetter?.start();
    set(isOnCameraAtom, false);
  } else {
    set(isOnCameraAtom, true);
    await set(grantVideoAtom);
    localAudioLevelGetter?.pause();
  }
  set(sendStompCameraStatusUpdatedAtom);
});

export const isVideoObjectFitCoverAtom = getAtomWithStorage<boolean>('isVideoObjectFitCover', true);

export const pushMatchViewModeEvent = atom(
  null,
  (get, set, { matchId, chatDurationMs }: { matchId: string; chatDurationMs?: number }) => {
    const width = window.innerWidth > 1280 ? 'long' : 'short';
    const mobileLayout = get(mobileLayoutAtom);
    const isVideoObjectFitCover = get(isVideoObjectFitCoverAtom);

    set(eventMutateAtom, {
      eventName: EVENT_NAME.MATCH__RESULT_VIEWMODE,
      eventType: EVENT_TYPE.VIEW,
      eventParams: {
        action_category: 'action',
        tab: 'mirror',
        page: 'match',
        target: 'result_viewmode',
        width,
        letterbox: isVideoObjectFitCover ? 'off' : 'on',
        match_id: matchId,
        chat_duration_ms: chatDurationMs,
        ...(width === 'short' && {
          split: mobileLayout === 'DEFAULT' ? 'off' : 'on',
        }),
      },
    });
  }
);

export const eventMatchPageAtom = atom((get) => {
  const status = get(statusAtom);
  switch (status) {
    case STATUS.INITIAL:
      return 'main';
    case STATUS.FINDING:
      return 'finding';
    case STATUS.CONNECTING:
      return 'connecting';
    case STATUS.CONTENTS_MATCH:
      return 'teammatch'; // 컨텐츠 매치 = 팀 매치 (동일 개념이지만 레거시로 용어 혼재)
    default:
      return 'match';
  }
});
