import { FC, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import LoadingIndicator from 'src/components/LoadingIndicator';
import GenderStep from 'src/components/LoginModal/Steps/Register/GenderStep';
import useEvent from 'src/hooks/useEvent';
import { socialTokenAtom, tryLoginTypeAtom } from 'src/stores/auth/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { pushPhoneNumberLoginHistoryAtom } from 'src/stores/phoneNumberLogin/atoms';
import { birthAtom, LoginModalType, loginModalTypeAtom, pushSocialRegisterHistoryAtom, signUpSettingsLoadableAtom } from 'src/stores/register/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
import { calculateAge } from 'src/utils/date';
import { AgeConfirmModal } from './AgeConfirmModal';
import { ScrollBirthdayInput } from './BirthdayInput/ScrollBirthdayInput';
const LoadingBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;
const RegisterBirthdayStep: FC = () => {
  const setBirth = useSetAtom(birthAtom);
  const [socialToken, setSocialToken] = useAtom(socialTokenAtom);
  const pushPhoneNumberLoginHistory = useSetAtom(pushPhoneNumberLoginHistoryAtom);
  const pushSocialRegisterHistory = useSetAtom(pushSocialRegisterHistoryAtom);
  const loginModalType = useAtomValue(loginModalTypeAtom);
  const signUpSettingsLoadable = useAtomValue(signUpSettingsLoadableAtom);
  const signUpSettings = signUpSettingsLoadable.state === 'hasData' ? signUpSettingsLoadable.data : null;
  const pushEvent = useEvent();
  const tryLoginType = useAtomValue(tryLoginTypeAtom);
  const showModal = useSetAtom(showModalAtom);
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPEN__LOGIN_BIRTHDAY_INFO,
      eventParams: {
        loginType: tryLoginType
      }
    });
  }, [pushEvent, tryLoginType]);
  const moveNextStep = useCallback(() => {
    switch (loginModalType) {
      case LoginModalType.PHONE_NUMBER_LOGIN:
        pushPhoneNumberLoginHistory(<GenderStep />);
        break;
      case LoginModalType.SOCIAL_REGISTER:
        pushSocialRegisterHistory(<GenderStep />);
        break;
      default:
        throw new Error('Invalid loginModalType in BirthdayStep');
    }
  }, [loginModalType, pushPhoneNumberLoginHistory, pushSocialRegisterHistory]);
  useEffect(() => {
    if (socialToken?.birth) {
      setBirth(socialToken.birth);
      setSocialToken({
        ...socialToken,
        birth: undefined
      });
      moveNextStep();
    }
  }, [moveNextStep, socialToken, setBirth, setSocialToken]);
  const handleSubmit = (date: Date) => {
    const blockAlertAge = signUpSettings?.birthdaySettings.blockAlertAge;
    const age = calculateAge(date);
    if (blockAlertAge && age <= blockAlertAge) {
      showModal({
        key: ModalType.INVALID_AGE_CHECK,
        component: () => <AgeConfirmModal userAge={age} limitedAge={blockAlertAge} />
      });
      return;
    }
    setBirth(date);
    moveNextStep();
  };
  return socialToken?.birth || signUpSettingsLoadable.state === 'loading' ? <LoadingBackground data-sentry-element="LoadingBackground" data-sentry-component="RegisterBirthdayStep" data-sentry-source-file="index.tsx">
      <LoadingIndicator size={18} data-sentry-element="LoadingIndicator" data-sentry-source-file="index.tsx" />
    </LoadingBackground> : <ScrollBirthdayInput onSubmit={handleSubmit} data-sentry-element="ScrollBirthdayInput" data-sentry-component="RegisterBirthdayStep" data-sentry-source-file="index.tsx" />;
};
export default RegisterBirthdayStep;