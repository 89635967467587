import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import Input from 'src/components/Input';
import RegisterBirthdayStep from 'src/components/LoginModal/Steps/Register/BirthdayStep';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import { phoneNumberLoginEmailAtom, pushPhoneNumberLoginHistoryAtom } from 'src/stores/phoneNumberLogin/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { emailReg } from 'src/utils/common';
import Template from './Template';
const EmailRow = styled.div`
  margin-top: 32px;
  width: 100%;
`;
const formId = 'email-address-register-step-form';

/** 새로 가입을 위해 이메일을 등록 */
const EmailAddressRegisterStep: FC = () => {
  const {
    t
  } = useTranslation();
  const device = useCssMediaDevice();
  const pushHistory = useSetAtom(pushPhoneNumberLoginHistoryAtom);
  const pushEvent = useEvent();
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPENED__EMAIL_INFO
    });
  }, [pushEvent]);
  const [email, setEmail] = useState('');
  const [phoneNumberLoginEmail, setPhoneNumberLoginEmail] = useAtom(phoneNumberLoginEmailAtom);

  // 가입을 위해 이메일을 등록
  const registerEmail = () => {
    setPhoneNumberLoginEmail({
      email,
      errorMessage: ''
    });
    pushHistory(<RegisterBirthdayStep />);
  };
  const handleInput = (text: string) => {
    setEmail(text);
    if (phoneNumberLoginEmail.errorMessage) {
      setPhoneNumberLoginEmail({
        ...phoneNumberLoginEmail,
        errorMessage: ''
      });
    }
  };
  const canRegisterEmail = emailReg(email) && !phoneNumberLoginEmail.errorMessage;
  return <>
      <Template dataTestId='LoginModal-EmailAddressRegisterStep' eventId='SIGNUP_PHONE_EMAIL_TITLE' title={t('SIGNUP_PHONE_EMAIL_TITLE')} description={t('SIGNUP_PHONE_EMAIL_DESC_RU')} mainButtonProps={{
      form: formId,
      type: 'submit',
      disabled: !canRegisterEmail,
      text: t('SIGNUP__NEXT'),
      'data-testid': 'LoginModal-EmailAddressRegisterStep-next'
    }} data-sentry-element="Template" data-sentry-source-file="EmailAddressRegisterStep.tsx">
        <EmailRow data-sentry-element="EmailRow" data-sentry-source-file="EmailAddressRegisterStep.tsx">
          <form id={formId} onSubmit={e => {
          e.preventDefault();
          if (canRegisterEmail) {
            registerEmail();
          }
        }}>
            <Input type='email' value={email} data-testid='LoginModal-EmailAddressRegisterStep-input' onChangeText={handleInput} placeholder={t('SIGNUP_PHONE_EMAIL_PLACEHOLDER')} errorMessage={phoneNumberLoginEmail.errorMessage} autoFocus={device !== 'mobile'} data-sentry-element="Input" data-sentry-source-file="EmailAddressRegisterStep.tsx" />
          </form>
        </EmailRow>
      </Template>
    </>;
};
export default EmailAddressRegisterStep;