import { ReactElement } from 'react';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';
import SmsVerificationPhoneNumberStep from 'src/components/LoginModal/Steps/SmsVerification/PhoneNumberStep';
import { smsPhoneNumberAtom, smsVerificationAtom } from 'src/stores/phoneNumberLogin/atoms';
import { LoginModalType, loginModalTypeAtom } from 'src/stores/register/atoms';
const initSocialLoginHistory = <SmsVerificationPhoneNumberStep />;
export const socialLoginHistoryAtom = atomWithReset([initSocialLoginHistory]);
export const pushSocialLoginHistoryAtom = atom(null, (get, set, nextStep: ReactElement) => {
  set(socialLoginHistoryAtom, history => [...history, nextStep]);
});
export const popSocialLoginHistoryAtom = atom(null, (get, set) => {
  set(socialLoginHistoryAtom, history => history.slice(0, -1));
});
export const exitSocialLoginAtom = atom(null, (get, set) => {
  set(loginModalTypeAtom, LoginModalType.LOGIN_ENTRY);
  set(smsPhoneNumberAtom, RESET);
  set(smsVerificationAtom, RESET);
});