import { FC } from 'react';
import styled from '@emotion/styled';
import { GemIncludedProductModule } from 'src/types/gemLack';
import { GemProduct } from 'src/types/Payments';
import getGemProductName, { GEM_TEXT_SEPARATOR } from 'src/utils/payments';
const Wrap = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`;
export const BaseGemText = styled.span`
  color: ${({
  theme
}) => theme.color.gray900__dkGray970};
`;
export const BonusGemText = styled.span`
  color: ${({
  theme
}) => theme.color.green500};
`;
interface Props {
  gemProduct: GemProduct | GemIncludedProductModule;
  className?: string;
}

/**
 * BaseGem이 아닌 유형의 젬이 존재할 경우 강조 스타일링을 부여하여 상품에서 제공하는 젬 수량 렌더링
 */
const GemAmountTextWithBonusHighlight: FC<Props> = ({
  gemProduct,
  className
}) => {
  const gemAmountText = getGemProductName(gemProduct);
  const [baseText, bonusText] = gemAmountText.split(GEM_TEXT_SEPARATOR, 2);
  return <Wrap className={className} data-sentry-element="Wrap" data-sentry-component="GemAmountTextWithBonusHighlight" data-sentry-source-file="index.tsx">
      <BaseGemText data-sentry-element="BaseGemText" data-sentry-source-file="index.tsx">{baseText}</BaseGemText>
      {bonusText && <BonusGemText>{`${GEM_TEXT_SEPARATOR}${bonusText}`}</BonusGemText>}
    </Wrap>;
};
export default GemAmountTextWithBonusHighlight;