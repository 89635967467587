import { atom } from 'jotai';

import { azarTokenAtom, remoteConfigAtom } from 'src/stores/auth/atoms';
import { visitorConfigAtom } from 'src/stores/visitor/atoms';
import { isObjectKeyTrue } from 'src/utils/common';

/**
 * 유저가 클라이언트에 접속한 시점
 */
export const userEnteredDateAtom = atom<Date | null>(null);

export const isAprilFoolsDayAtom = atom((get) => {
  const userEnteredDate = get(userEnteredDateAtom);
  const userEnteredAtAprilFoolsDay =
    userEnteredDate !== null && userEnteredDate.getMonth() === 3 && userEnteredDate.getDate() === 1;

  if (!userEnteredAtAprilFoolsDay) return false;

  if (get(azarTokenAtom)) {
    const remoteConfig = get(remoteConfigAtom);
    return !!remoteConfig && isObjectKeyTrue(remoteConfig, 'showAprilFoolsDayWebEvent');
  } else {
    const visitorConfig = get(visitorConfigAtom);
    return !!visitorConfig && isObjectKeyTrue(visitorConfig, 'AprilFoolsUi');
  }
});
