import styled from '@emotion/styled';

import ModalLayoutBase, {
  ModalContent,
  ModalInner,
  ModalTitleContainer,
} from 'src/components/ModalLayout';
import { hoverAndActiveCss } from 'src/utils/styled';

export const ModalLayout = styled(ModalLayoutBase)<{ minHeight?: string }>`
  width: 500px;

  ${({ minHeight }) =>
    minHeight
      ? `
      min-height: ${minHeight};
      height: auto;

      & > ${ModalInner} {
        min-height: inherit;
      }
  `
      : `
    height: 600px;
  `}
`;

export const ButtonWrap = styled.div`
  ${({ theme }) => theme.screenSize.mobile} {
    position: fixed;
    bottom: 0;
    padding: 24px;
    padding-bottom: 32px;
    width: var(--100vw);
    left: 0;
  }
`;

export const Wrap = styled(ModalContent)`
  width: 100%;
  flex-grow: 1;
  ${({ theme }) => theme.screenSize.mobile} {
    min-height: 0px;
  }
`;

export const Title = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.screenSize.mobile} {
    font-size: 40px;
  }
`;

export const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ theme }) => theme.color.gray500__dkGray400};
  ${({ theme }) => theme.screenSize.mobile} {
    font-size: 12px;
  }
`;

export const GenderDesc = styled.p`
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
  margin-top: 16px;
  color: ${({ theme }) => theme.color.gray400__dkGray300};
`;

export const SelectChildGenderButton = styled.button`
  color: ${({ theme }) => theme.color.gray600__dkGray500};
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 10px;
  background: ${({ theme }) => theme.color.gray60__dkGray100};
  text-align: start;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 40px;
`;

export const GenderButton = styled.button<{ inactive: boolean }>`
  width: 100%;
  padding: 18px 24px;
  color: ${({ theme, inactive }) =>
    inactive ? theme.color.gray300__dkGray250 : theme.color.gray900__dkGray970};
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 16px;
  text-align: start;
  background: ${({ theme }) => theme.color.gray100__dkGray150};
  &:not(:first-of-type) {
    margin-top: 16px;
  }
  ${({ theme }) =>
    hoverAndActiveCss(`
      background-color: ${theme.color.gray200__dkGray200};
      ${SelectChildGenderButton} {
        background-color: ${theme.color.gray100__dkGray150};
      }
  `)}
`;

export const LoginModalHeader = styled(ModalTitleContainer)`
  padding: 24px 0px;
`;

export const MobileDatePickerWrap = styled.div`
  position: relative;
  display: none;
  ${({ theme }) => theme.screenSize.mobile} {
    display: flex;
  }
  width: 100%;
`;

export const LoadingBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.5;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 20px;
  ${({ theme }) => theme.screenSize.mobile} {
    border-radius: 0;
  }
`;
