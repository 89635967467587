import { type Interpolation, css, Global } from '@emotion/react';
import appConfig from 'app-config';
const commonStyles = css`
  /**
   * iPhone X 이후 iOS Safari 를 위한 safe area inset 추가
   * @see {@link https://webkit.org/blog/7929/designing-websites-for-iphone-x/}
   * @see {@link https://git.dev.hpcnt.com/hyperconnect/azar-web-client/pull/835/commits/c2d3ea9c540b544fe8fadd4b21fea2e3db563da2}
   */
  :root {
    /* iOS 11.0 버전 */
    --sat: constant(safe-area-inset-top);
    --sar: constant(safe-area-inset-right);
    --sab: constant(safe-area-inset-bottom);
    --sal: constant(safe-area-inset-left);
    /* iOS 11.2 이상 */
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
  }

  html {
    display: flex;
    min-height: 100%;

    user-select: none;
  }

  body,
  #__next {
    display: flex;
    flex-grow: 1;
  }

  /**
   * button 의 기본 스타일링 제거 및 Safari 에서만 발생하는 파란색 오버레이 제거
   * {@see @link https://git.dev.hpcnt.com/hyperconnect/azar-web-client/commit/a1a6102813c0dc2daba9b4f922cf85c9fcc5ddd8}
   */
  button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 0;
  }

  [role='button'],
  button:focus,
  button:active,
  a,
  a:focus,
  a:active {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  p {
    margin: 0;
    word-break: keep-all;
    white-space: pre-wrap;
  }

  div {
    box-sizing: border-box;
  }

  /**
   * iOS에서 long press 시 이미지가 확대되는 기능 비활성화
   * @see {@link https://git.dev.hpcnt.com/hyperconnect/azar-web-client/pull/487/commits/2b571ee68f5e2439f3d7ccdc051ec79e0ed35534}
   */
  img,
  source {
    -webkit-touch-callout: none;
  }
`;
const azarStyles: Interpolation<Theme> = theme => css`
  ${commonStyles}

  html {
    /**
     * TODO: next.js 업그레이드 이후 next/font 를 사용하도록 변경이 필요
     */
    font-family:
      'Poppins',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      'Noto Sans',
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
  }

  body {
    background-color: ${theme.color.gray30__dkGray50};
  }

  @media only screen and (max-width: 768px) {
    #onetrust-banner-sdk #onetrust-policy {
      margin-top: 24px !important;
      margin-bottom: 24px !important;
      margin-left: 4px !important;
      margin-right: 4px !important;
    }
  }

  #onetrust-consent-sdk #onetrust-banner-sdk {
    background-color: ${theme.color.gray30__dkGray50} !important;
  }
`;
const vividiStyles: Interpolation<Theme> = theme => css`
  ${commonStyles}

  html {
    font-family: system-ui, sans-serif;
  }

  body {
    background-color: ${theme.color.whiteBg__dkBlackBg};
  }
`;
const GlobalStyles = () => {
  switch (appConfig.serviceCode) {
    case 'azar':
      return <Global styles={azarStyles} />;
    case 'vividi':
      return <Global styles={vividiStyles} />;
    default:
      return <Global styles={commonStyles} />;
  }
};
export default GlobalStyles;