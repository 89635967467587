import { PG_ERROR_CODE } from './Payments';

export const EVENT_API_URL = process.env.NEXT_PUBLIC_EVENT_FUNNEL_URL || '';

export const EVENT_TYPE = {
  SIGN_UP: 'SIGN_UP',
  WEB: 'WEB',
  LIVE: 'LIVE',
  SHOP: 'SHOP',
  VIDEO_CHAT: 'VIDEOCHAT',
  VIDEO_CHAT_DEBUG: 'VIDEOCHAT_DEBUG',
  PURCHASE: 'PURCHASE',
  VIP: 'VIP',
  PAYMENT: 'PAYMENT',
  BUTTON: 'BUTTON',
  GNB: 'GNB',
  UTM: 'UTM',
  EDIT_PROFILE: 'EDIT_PROFILE',
  EVENT_PAGE: 'EVENT_PAGE',
  HISTORY: 'HISTORY',
  VISITOR: 'VISITOR',
  DECO: 'DECO',
  REPORT: 'REPORT',
  VIEW: 'VIEW',
  MIRROR: 'MIRROR',
  BLOG: 'BLOG',
  WEB2APP: 'WEB2APP',
  DSAR: 'DSAR',
  DOWNLOAD: 'DOWNLOAD',
  POPUP: 'POPUP',
  DEBUG: 'DEBUG',
} as const;

export const EVENT_NAME = {
  VIP_SYSTEM__IMPRESSION: 'vip_system__impression',
  VIP_SYSTEM__CLICK_PURCHASE: 'vip_system__click_purchase',
  VIP_SYSTEM__PURCHASE_DONE: 'vip_system__purchase_done',
  VIP_SYSTEM__CLICK_BUTTON: 'vip_system__click_button',
  POPUP_OPEN: 'popup_opened',
  SCREEN_OPEN__LOGIN: 'screen_opened__Login',
  SCREEN_OPEN__LOGIN_CHAT: 'screen_opened__Login_Chat',
  SCREEN_OPEN__LOGIN_METHOD: 'screen_opened__LoginMethod',
  SCREEN_OPEN__LOGIN_TERMS: 'screen_opened__Terms',
  SCREEN_OPEN__LOGIN_BIRTHDAY_INFO: 'screen_opened__BirthdayInfo',
  SCREEN_OPEN__LOGIN_GENDER_INFO: 'screen_opened__GenderInfo',
  SCREEN_OPEN__LOGIN_COMMUNITY: 'screen_opened__CommunityGuideline',
  SCREEN_OPEN__LOGIN_AGE_RESTRICTED: 'screen_opened__AgeRestricted',
  SCREEN_OPENED__PHONE_NUMBER: 'screenopened__VerifyPhoneNumber',
  SCREEN_OPENED__PHONE_VERIFICATION: 'screen_opened_SmsVerify',
  SCREEN_OPENED__EMAIL_INFO: 'screen_opened__EmailInfo',
  SCREEN_OPENED: 'screen_opened',
  LOGIN_SUCCESS: 'login_success',
  SCREEN_OPENED__MAIN_PAGE: 'screen_opened__Main_page',
  JOIN_LIVE_NOW: 'Join_live_now',
  SIGNUP_COMPLETE: 'Signup_complete',
  // signup
  SIGN_UP_CLICK_RESEND: 'verify_click_RetryVerification',
  SIGNUP__SELECT_GENDER: 'signup__select_gender',
  SIGNUP__CLICK_HIDE_GENDER: 'signup__click_hide_gender',
  ANYWHERE__REPORT_UNDERAGE_BLOCK: 'anywhere__report_underage_block',

  MIRROR__CLICK_VIDEO_CHAT_START: 'mirror__click_video_chat_start',
  ACCESS_REQUIRED_POPUP: 'accessrequired_popup_opened',
  SCREEN_OPENED__MATCH_FINDING: 'screen_opened__Match_finding',
  BLUR__CONTINUE_MATCH: 'blur__continue_match',
  BLUR__REPORT_AND_LEAVE: 'blur__report_and_leave',
  MATCH_FINDING__PROFILE: 'match_finding__profile',
  MATCH_RECEIVE__STOMP_MESSAGE: 'match_receive__stomp_message',
  MATCH_UUID: 'match_uuid',
  MATCHING_ACTIVATE_BLUR: 'matching__activate_blur',
  MATCHING__NEXT: 'matching__next',
  SHOP_OPEN: 'shop_open',
  PURCHASE_COMPLETE: 'purchase_complete',
  MATCHING__EXIT: 'matching__exit',
  DROP_IN_ERROR: 'drop_in_error',
  REDIRECT_DROP_IN_ERROR: '3d_secure_drop_in_error',
  API_ERROR: 'api_error',
  FINDING__OPEN_GENDERFILTER: 'finding__open_genderfilter',
  FINDING__CLICK_GENDERFILTER: 'finding__click_genderfilter',
  FINDING__FILTER_BOTH: 'finding__filter_both',
  FINDING__FILTER_FEMALE: 'finding__filter_female',
  FINDING__FILTER_MALE: 'finding__filter_male',
  FINDING__FILTER_DEFAULT: 'finding__filter_default',
  FINDING__GENDERFILTER_GOMATCH: 'finding__genderfilter_gomatch',
  FINDING__GENDERFILTER_GOSHOP: 'finding__genderfilter_goshop',
  FINDING__GENDERFILTER_CLOSE: 'finding__genderfilter_close',
  MIRROR__OPEN_GENDERFILTER: 'mirror__open_genderfilter',
  MIRROR__CLICK_GENDERFILTER: 'mirror__click_genderfilter',
  MIRROR__FILTER_BOTH: 'mirror__filter_both',
  MIRROR__FILTER_FEMALE: 'mirror__filter_female',
  MIRROR__FILTER_MALE: 'mirror__filter_male',
  MIRROR__FILTER_DEFAULT: 'mirror__filter_default',
  MIRROR__GENDERFILTER_GOMATCH: 'mirror__genderfilter_gomatch',
  MIRROR__GENDERFILTER_GOSHOP: 'mirror__genderfilter_goshop',
  MIRROR__GENDERFILTER_CLOSE: 'mirror__genderfilter_close',
  CAMERA_OFF_BUTTON_CLICK: 'camera_off_button_click',

  FINDING__OPEN_LOCATIONFILTER: 'finding__open_locationfilter',
  FINDING__CLICK_LOCATIONFILTER: 'finding__click_locationfilter',
  FINDING__LOCATIONFILTER_GOMATCH: 'finding__locationfilter_gomatch',
  FINDING__LOCATIONFILTER_GOSHOP: 'finding__locationfilter_goshop',
  FINDING__LOCATIONFILTER_CLOSE: 'finding__locationfilter_close',
  MIRROR__OPEN_LOCATIONFILTER: 'mirror__open_locationfilter',
  MIRROR__CLICK_LOCATIONFILTER: 'mirror__click_locationfilter',
  MIRROR__LOCATIONFILTER_GOMATCH: 'mirror__locationfilter_gomatch',
  MIRROR__LOCATIONFILTER_GOSHOP: 'mirror__locationfilter_goshop',
  MIRROR__LOCATIONFILTER_CLOSE: 'mirror__locationfilter_close',
  MIRROR__CLICK_NEXT_VIDEO_CHAT: 'mirror__click_next_video_chat',
  MIRROR__CLICK_MATCH_END: 'mirror__click_match_end',

  CONSENT_POPUP__IMPRESSION: 'consent_popup__impression',
  VERIFY_CLICK_GET_VERIFICATION_CODE: 'verify_clickGetVerificationCode',
  CLICK_BUTTON: 'click_button',
  GNB__CLICK_MAIN: 'gnb__click_main',
  GNB__CLICK_TAB: 'gnb__click_tab',
  GNB__CLICK_SHOP: 'gnb__click_shop',
  GNB__CLICK_LOGIN: 'gnb__click_login',
  GNB__CLICK_PROFILE: 'gnb__click_profile',
  GNB__CLICK_ACCOUNT: 'gnb__click_account',
  GNB__CLICK_CONTACTUS: 'gnb__click_contactus',
  GNB__CLICK_LOGOUT: 'gnb__click_logout',
  GNB__CLICK_APPSTORE: 'gnb__click_appstore',
  GNB__CLICK_PLAYSTORE: 'gnb__click_playstore',

  // 젬부족 모달
  GEM_LACK_POPUP__IMPRESSION: 'gem_lack_popup__impression',
  GEM_LACK_POPUP__ITEM_CLICK: 'gem_lack_popup__item_click',
  GEM_LACK_POPUP__CLICK_CLOSE: 'gem_lack_popup__click_close',
  ANYWHERE__REPORT_GEMLACK_INSPECTION: 'anywhere__report_gemlack_inspection',

  UTM_FIRST_OPEN: 'utm_first_open',
  EDIT_PROFILE_MODAL__CLICK_CLOSE: 'edit_profile_modal__click_close',
  EDIT_PROFILE_MODAL__IMPRESSION: 'edit_profile_modal__impression',
  EDIT_PROFILE_MODAL__DONE: 'edit_profile_modal__done',
  FIRST_OPEN: 'first_open',
  FIRST_SESSION_OPEN: 'first_session_open',
  EVENT_PAGE_IMPRESSION: 'event_page__impression',
  EVENT_PAGE__IMPRESSION_EVENT_PAGE: 'event_page__impression_event_page',
  EVENT_PAGE_CLICK_BUTTON: 'event_page__click_button',
  OBS_REJECT_OPEN: 'obs_reject_open',
  OBS_REJECT_CLOSE: 'obs_reject_close',
  HISTORY_OPEN: 'HISTORY_OPEN',
  CHAT_CLICK: 'chat_click',
  HISTORY_PROFILE_CARD_CLICK: 'history_profile_card_click',
  HISTORY_FILTER_CLICK: 'history_filter_click',
  HISTORY_OPTION_SELECT: 'history_option_select',
  LIVE_GUIDELINE_OPEN: 'live_guideline_open',
  LIVE_GUIDELINE_CLICK: 'live_guideline_click',
  LIVE_START: 'live_start',
  LIVE_SETTING_EDIT: 'live_setting_edit',
  LIVE_END: 'live_end',
  LIVE_VIEWER_CLICK: 'live_viewer_click',
  LIVE_END_CONFIRM: 'live_end_confirm',
  LIVE_END_CANCEL: 'live_end_cancel',
  LIVE_PROFILE_MODAL_OPEN: 'live_profile_modal_open',
  LIVE_SUMMARY_MODAL: 'live_summary_modal_open',
  LIVE_NEXT_MODAL: 'live_next_modal',
  GIFT_SEND: 'gift_send',
  GIFT_SELECT: 'gift_select',
  GIFT_SEND_BUTTON_CLICK: 'gift__send_button',

  VISITOR_CONFIG: 'visitor_config',
  OPEN_DECO_STUDIO: 'open_deco_studio',
  LOAD_FAILED_DECO_MODEL: 'load_failed_deco_model',
  CLICK_DECO_STUDIO_SELECT_ITEM: 'click_deco_studio_select_item',
  REPORT_BLOCK_CLICK: 'report_block_click',
  REPORT_BLOCK_REASON_POPUP_SELECT: 'report_block_reason_popup_select',
  REPORT_USER: 'report_user',

  // Shop
  ANYWHERE__CLICK_SHOP_PRODUCT: 'anywhere__click_shop_product',

  // SETTINGS
  SETTINGS__CLICK_HIDE_GENDER: 'settings__click_hide_gender',

  AHAMOMENT_MATCH_10MIN: 'ahamoment__match_10min',
  AHAMOMENT_MATCH_20MIN: 'ahamoment__match_20min',
  AHAMOMENT_MATCH_30MIN: 'ahamoment__match_30min',

  // 여백모드
  MIRROR_CLICK_LETTERBOX: 'mirror__click_letterbox',
  MIRROR_CLICK_SPLIT: 'mirror__click_split',
  MATCH__RESULT_VIEWMODE: 'match__result_viewmode',

  // 데코 이펙트
  MIRROR_REPORT_FAIL_EFFECT: 'mirror__report_fail_effect',
  MIRROR_REPORT_NETWORK_FAIL_EFFECT: 'mirror__report_network_fail_effect',
  MIRROR_REPORT_SAVE_EFFECT: 'mirror__report_save_effect', // 이펙트 성공적으로 적용 완료
  MIRROR_REPORT_EFFECT_AVAILABLE: 'mirror__report_effect_available',
  MIRROR__REPORT_GESTURE_TOGGLE: 'mirror__report_gesture_toggle',
  MIRROR__REPORT_OPERATE_GESTURE: 'mirror__report_operate_gesture',
  MIRROR__OPEN_GESTURE_EFFECT: 'mirror__open_gesture_modal', // 제스쳐 이펙트 모달 오픈
  MIRROR__IMPRESSION_GESTURE_SURVEY: 'mirror__impression_gesture_survey', // (임시) 제스쳐 이펙트 서베이 노출 시
  MIRROR__CLICK_GESTURE_SURVEY_BUTTON: 'mirror__click_gesture_survey_button', // (임시) 제스쳐 이펙트 서베이에서 up / down 클릭 시

  //Blog
  BLOG__OPEN_TAB: 'blog__open_tab',
  BLOG__OPEN_CONTENT: 'blog__open_content',
  BLOG_CLICK_VIDEO_CHAT_START: 'blog__click_video_chat_start',

  //WebToAppModal
  ANYWHERE__CLICK_TO_APP_MODAL_BTN: 'anywhere__click_to_app_modal_btn',
  ANYWHERE__IMPRESSION_TO_APP_MODAL_BTN: 'anywhere__impression_to_app_modal',

  // DSAR
  ANYWHERE__IMPRESSION_DATA_REQUEST_FORM: 'anywhere__impression_data_request_form',
  ANYWHERE__SUBMIT_DATA_REQUEST_FORM: 'anywhere__submit_data_request_form',

  // 회원가입 A/B 테스트
  SIGNUP__IMPRESSION_BIRTHDAY_INFO_RETRY_POPUP: 'signup__impression_birthday_info_retry_popup',
  SIGNUP__CLICK_BIRTHDAY_INFO_RETRY_BUTTON: 'signup__click_birthday_info_retry_button',
  SIGNUP__IMPRESSION_FEATURE_CONSENT_POPUP: 'signup__impression_feature_consent_popup',
  SIGNUP__CLICK_FEATURE_CONSENT_POPUP: 'signup__click_feature_consent_popup',

  // 다운도르 페이지 (mobi2app)
  DOWNLOAD__CLICK_DOWNLOAD_BUTTON: 'download__click_download_button',

  // 스타터패키지
  STARTER_PACKAGE_POPUP_V1_IMPRESSION: 'starterpackage_popup_v1_impression',
  STARTER_PACKAGE_POPUP_V1_CLICK_BUTTON: 'starterpackage_popup_v1_click_button',
  STARTER_PACKAGE_POPUP_V1_CLOSE: 'starterpackage_popup_v1_close',
  ANYWHERE_IMPRESSION_BOUNCE_POPUP: 'anywhere__impression_bounce_popup',
  ANYWHERE_CLICK_BOUNCE_POPUP_BUTTON: 'anywhere__click_bounce_popup_button',
  ANYWHERE_IMPRESSION_CONSENT_POPUP: 'anywhere__impression_consent_popup',
  ANYWHERE_CLICK_CONSENT_BUTTON: 'anywhere__click_consent_button',
  ANYWHERE_CLICK_LINK_BUTTON: 'anywhere__click_link_button',

  // 임시 비디오 채팅 디버그
  DEBUG__MATCH_LOGGING: 'debug__match_logging',

  // 디버그 로그
  GENERAL_CONNECTION_FAIL: 'generalConnection_fail',
} as const;

export const SERVICE_CODE = { AZAR: 'AZAR' } as const;

export const API_NAME = {
  GET_BILLING_INFO: 'get_billing_info',
  RESERVE_PAYMENT: 'reserve_payment',
  COMPLETE_PAYMENT: 'complete_payment',
} as const;

export const ERROR_STATUS = {
  USER_OUT: 'USER_OUT',
  FAIL: 'FAIL',
} as const;

export const getErrorStatus = (code: string) =>
  code === PG_ERROR_CODE.PAY_PROCESS_CANCELED || code === 'Cancelled'
    ? ERROR_STATUS.USER_OUT
    : ERROR_STATUS.FAIL;

export type EventType = (typeof EVENT_TYPE)[keyof typeof EVENT_TYPE];
export type EventName = (typeof EVENT_NAME)[keyof typeof EVENT_NAME];
export type EventOption = {
  [key: string]: string | Record<string, string | string[] | undefined>;
};

export const EVENT_LOGIN_MODAL_VISITOR_TRIGGER = {
  GENDER_BUTTON: 'gender_button',
  LOCATION_BUTTON: 'location_button',
  MATCH_START_BUTTON_MIRROR: 'match_start_button_mirror',
  MATCH_START_BUTTON_HISTORY: 'match_start_button_history',
  OBJECT_FIT_BUTTON: 'object_fit_button',
  DECO_FILTER_BUTTON: 'effect_icon_button',
  GESTURE_EFFECT_ICON_BUTTON: 'gesture_effect_icon_button',
  HEADER_SHOP: 'header_shop',
  LOGIN_BUTTON: 'login_button',
  RENEW_ACCESS_TOKEN: 'renewaccesstoken',
  BOUNCE_POPUP: 'bounce_popup',
} as const;

export type EventLoginModalVisitorTrigger =
  (typeof EVENT_LOGIN_MODAL_VISITOR_TRIGGER)[keyof typeof EVENT_LOGIN_MODAL_VISITOR_TRIGGER];
