import { differenceInMilliseconds } from 'date-fns';
import { atom } from 'jotai';
import { exhaustMap } from 'rxjs';

import SuspensionModal from 'src/components/SuspensionModal';
import { removeTokenAtom, userDataAtom } from 'src/stores/auth/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { ErrorResponse, PunishedException } from 'src/utils/api';
import { atomWithPipes } from 'src/utils/atom';
import { isAxiosError } from 'src/utils/error';
import { getAtomWithStorage, reviveDateFromStorage } from 'src/utils/localStorageUtils';

import { getFeatureSettingsAPI, getUpdatedUserInfoAPI, setFeatureSettingsAPI } from './api';
import { FeatureSettings, SetFeatureSettingsParam } from './types';

export const featureSettingsAtom = atom<FeatureSettings['featureSettings'] | null>(null);

export const getFeatureSettingsAtom = atom(null, (_get, set) => {
  getFeatureSettingsAPI()
    .then(({ data }) => {
      set(featureSettingsAtom, data.result.featureSettings);
    })
    .catch(() => {});
});

export const setFeatureSettingsAtom = atom(
  null,
  async (get, set, params: SetFeatureSettingsParam['featureSettings']) => {
    await setFeatureSettingsAPI({ featureSettings: params });
    const featureSettings = get(featureSettingsAtom);

    const newSettings = featureSettings ? [...featureSettings] : null;
    if (newSettings) {
      for (const param of params) {
        for (const setting of newSettings) {
          if (setting.name === param.type) {
            setting.value = param.value;
            break;
          }
        }
      }
      set(featureSettingsAtom, newSettings);
    }
  }
);

const DEVICE_ID_KEY = 'deviceId';
export const deviceIdAtom = getAtomWithStorage<string | void>(DEVICE_ID_KEY, undefined);

export const lastUserInfoUpdateDateAtom = getAtomWithStorage<Date | null>(
  'lastGetSegmentTime',
  null,
  {
    reviver: reviveDateFromStorage,
  }
);

const DEFAULT_REFRESH_USER_INFO_MIN_INTERVAL_SEC = 10; // 10초

export const getUpdatedUserInfoAtom = atomWithPipes(
  null,
  async (get, set) => {
    const userData = get(userDataAtom);
    if (!userData) return;
    const lastUserInfoUpdateDate = get(lastUserInfoUpdateDateAtom);
    if (
      /**
       * 새로고침했을 때 가장 최근에 유저 정보를 갱신한 시점에서 일정 시간 이내라면 최신화된 상태로 간주하고 미갱신
       */
      lastUserInfoUpdateDate &&
      differenceInMilliseconds(new Date(), lastUserInfoUpdateDate) <
        (userData.remoteConfig.refreshUserInfoMinIntervalInSeconds ??
          DEFAULT_REFRESH_USER_INFO_MIN_INTERVAL_SEC) *
          1000
    )
      return;
    try {
      const {
        data: { result },
      } = await getUpdatedUserInfoAPI();
      set(lastUserInfoUpdateDateAtom, new Date());
      set(
        userDataAtom,
        (prevUserData) =>
          prevUserData && {
            ...prevUserData,
            remoteConfig: result.remoteConfig,
            featureOptions: result.featureOptions,
            userProfile: {
              ...prevUserData.userProfile,
              location: result.location,
            },
          }
      );
    } catch (err) {
      if (!isAxiosError<ErrorResponse<PunishedException>>(err)) return;
      const code = err?.response?.data.error?.code;
      const errorBody = err?.response?.data.error?.data;
      if (!code || !errorBody) return;
      switch (code) {
        case 'PUNISHED':
          set(showModalAtom, {
            key: ModalType.LOGIN_SUSPENSION,
            component: () =>
              SuspensionModal({
                punishment: errorBody,
              }),
          });
          set(removeTokenAtom);
          break;
        case 'UNAUTHORIZED':
          set(removeTokenAtom);
          break;
        default:
          throw err;
      }
    }
  },
  [exhaustMap]
);
