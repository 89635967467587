import { FC, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { debounceTime, fromEvent, map } from 'rxjs';
import Gap from 'src/components/Gap';
const Wrap = styled.div`
  padding: ${({
  theme
}) => theme.isRtl ? '12px 16px 12px 5px' : '12px 5px 12px 16px'};
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: ${({
  theme
}) => theme.color.gray100__dkGray150};
  height: 44px;
  :focus-within {
    border: 1px solid ${({
  theme
}) => theme.color.green500};
  }
`;
const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
`;
const ResetIcon = styled.img`
  width: 18px;
  height: 18px;
`;
const InputBase = styled.input`
  color: ${({
  theme
}) => theme.color.gray900__dkGray970};
  font-size: 15px;
  border: none;
  background-color: transparent;
  flex-grow: 1;
  :focus {
    outline: none;
  }
`;
const ResetButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface Props {
  onChangeText: (text: string) => void;
  value: string;
}
const Input: FC<Props> = ({
  onChangeText,
  value
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    t
  } = useTranslation();
  useEffect(() => {
    if (!inputRef.current) return;
    const subscription = fromEvent(inputRef.current, 'input').pipe(debounceTime(300), map(event => (event.target as HTMLInputElement).value)).subscribe(searchText => {
      onChangeText(searchText);
    });
    return () => subscription.unsubscribe();
  }, [onChangeText]);
  return <Wrap data-sentry-element="Wrap" data-sentry-component="Input" data-sentry-source-file="Input.tsx">
      <SearchIcon src='/images/icons/icSearch.svg' alt='search' data-sentry-element="SearchIcon" data-sentry-source-file="Input.tsx" />
      <Gap width={8} data-sentry-element="Gap" data-sentry-source-file="Input.tsx" />
      <InputBase ref={inputRef} placeholder={t('signup_nonbinary_search')} data-sentry-element="InputBase" data-sentry-source-file="Input.tsx" />
      <Gap width={8} data-sentry-element="Gap" data-sentry-source-file="Input.tsx" />
      {!!value && <ResetButton>
          <ResetIcon src='/images/icons/icCloseCircle.svg' alt='reset' />
        </ResetButton>}
    </Wrap>;
};
export default Input;