import { client } from 'src/utils/api';

import { UserProfile } from './types';

export const getProfileAPI = (id: string) =>
  client.get<UserProfile>(`/api/v1/profiles/types/MY_PROFILE/ids/${id}`);

interface UploadProfileImageBody {
  bucketName: string;
  objectName: string;
}
export const uploadProfileImageAPI = async (body: UploadProfileImageBody) =>
  client.post('/api/v1/profiles/images', body);

export const deleteProfileImageAPI = async (profileImageId: number) =>
  client.delete(`/api/v1/profiles/images/${profileImageId}`);

interface OrderProfileImagesBody {
  orders: number[];
}
export const orderProfileImagesAPI = async (body: OrderProfileImagesBody) =>
  client.put('/api/v1/profiles/images/orders', body);

interface UpdateProfileBioBody {
  profileBio: string;
}
export const updateProfileBioAPI = async (body: UpdateProfileBioBody) =>
  client.put('/api/v1/profiles/bio', body);

export const updateMainProfileBioAPI = async (body: UpdateProfileBioBody) =>
  client.put('/api/v1/profiles/bio', body);

type UpdateMainProfileImageBody = UploadProfileImageBody;
export const updateMainProfileImageAPI = async (body: UpdateMainProfileImageBody) =>
  client.put('/api/v1/profiles/images/main', body);

export const getHashtagsAPI = async () => client.get('/api/v1/hashtags');

interface UpdateHashtagsBody {
  hashtags: string[];
}
export const updateHashtagsAPI = async (body: UpdateHashtagsBody) =>
  client.put('/api/v1/hashtags', body);
